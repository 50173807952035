import React from 'react'
import { useTranslation } from 'react-i18next'
import BottomMainMenuComponent from '@doinn/shared/src/components/app/BottomMainMenu'

const BottomMainMenu = () => {
  const { t } = useTranslation()
  const items = [
    {
      id: 'messages',
      link: '/',
      label: t('Messages'),
      icon: 'message_text',
      isAllowed: true,
      badgeCounter: 0
    }
  ]

  return <BottomMainMenuComponent items={items} />
}

export default BottomMainMenu
