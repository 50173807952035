import React from 'react'
import PropTypes from 'prop-types'
import { VirtualListContext } from '@doinn/shared/src/components/common/virtual-list/VirtualList'

const VirtualListItem = ({ children, index }) => {
  const { setSize, sizeMap, listRef } = React.useContext(VirtualListContext)
  const item = React.useRef()

  React.useEffect(() => {
    const oldSize = sizeMap[index]
    const newSize = item.current.getBoundingClientRect().height
    setSize(index, newSize)
    if (newSize !== oldSize) {
      listRef.current.resetAfterIndex(index, true)
    }
  }, [sizeMap, setSize, listRef, index])

  return <div ref={item}>{children}</div>
}

VirtualListItem.defaultProps = {
  index: PropTypes.number.isRequired
}

export default VirtualListItem
