import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory as createHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { reduxSearch } from 'redux-search'
import createRootReducer from 'store/reducers'
import sagas from 'store/sagas'
import snackbarMiddleware from '@doinn/shared/src/middlewares/snackbar'
import trackerMiddleware from '@doinn/shared/src/middlewares/tracker'

export const history = createHistory({ basename: '' })
export const store = configureStore()
export default function configureStore(preloadedState) {
  const rootReducer = createRootReducer(history)
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [
    routerMiddleware(history),
    sagaMiddleware,
    snackbarMiddleware,
    trackerMiddleware
  ]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const reduxSearchEnhancer = reduxSearch({
    resourceIndexes: {
      chats: ({ resources, indexDocument, state }) => {
        resources.forEach(chat => {
          if (chat.name) {
            indexDocument(chat.id, chat.name)
          }
          chat.participants.forEach(participant =>
            indexDocument(chat.id, participant.name)
          )
        })
      },
      chatsContacts: ['name'],
      chatsProperties: ['name', 'address']
    },
    resourceSelector: (resourceName, state) => {
      return state[resourceName].list
    }
  })
  const enhancers = [middlewareEnhancer, reduxSearchEnhancer]
  const composeEnhancersWithDevTools = composeWithDevTools({})
  const composedEnhancers = composeEnhancersWithDevTools(...enhancers)
  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  sagaMiddleware.run(sagas)

  return store
}
