// @flow
/**
 * Based on https://github.com/alibaba/hooks/blob/master/packages/hooks/src/usePersistFn/index.ts
 */
import { useCallback, useRef } from 'react'

function usePersistFn(fn: () => {}) {
  const ref = useRef(() => {
    throw new Error('Cannot call function while rendering.')
  })

  ref.current = fn

  return useCallback((...args) => ref.current(...args), [ref])
}

export default usePersistFn
