/**
 * Creates a theme for Material UI
 * https://material-ui.com/customization/default-theme/
 */
import { createMuiTheme } from '@material-ui/core/styles'
import palette from '@doinn/shared/src/config/theme/palette'
import props from '@doinn/shared/src/config/theme/props'
import shadows from '@doinn/shared/src/config/theme/shadows'
import { withMixinsVariants } from '@doinn/shared/src/config/theme/mixins'
import overrides, {
  withOverridesVariants
} from '@doinn/shared/src/config/theme/overrides'
import typography, {
  withTypographyVariants
} from '@doinn/shared/src/config/theme/typography'

const themeName = 'Doinn Material UI Theme'

let theme = createMuiTheme({
  themeName,
  typography,
  palette,
  shape: {
    borderRadius: 8
  },
  shadows,
  props,
  overrides
})

theme = withMixinsVariants(theme)
theme = withTypographyVariants(theme)
theme = withOverridesVariants(theme)

export default theme
