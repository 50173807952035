import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  const mobileBreakpoint = theme.breakpoints.down('xs')

  return {
    main: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      [mobileBreakpoint]: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(3)
      }
    }
  }
})

const MainContent = props => {
  const { children } = props
  const classes = useStyles()

  return <div className={classes.main}>{children}</div>
}

export default MainContent
