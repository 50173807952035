import React from 'react'
import PropTypes from 'prop-types'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import MaterialSnackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles } from '@material-ui/core/styles'
import Icon from '@doinn/shared/src/components/common/Icon'

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'alert_circle',
  info: 'information'
}

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.info
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const Snackbar = props => {
  const classes = useStyles()
  const {
    show,
    message,
    variant,
    onClose,
    verticalPosition,
    horizontalPosition,
    autoHideDuration,
    onExited
  } = props
  const snackbarIcon = variantIcon[variant]
  return (
    <div>
      <MaterialSnackbar
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition
        }}
        open={show}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        onExited={onExited}
      >
        <SnackbarContent
          className={classes[variant]}
          aria-describedby='client-snackbar'
          message={
            <span id='client-snackbar' className={classes.message}>
              <Icon className={classes.icon} icon={snackbarIcon} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              onClick={onClose}
            >
              <Icon icon={'close'} />
            </IconButton>
          ]}
        />
      </MaterialSnackbar>
    </div>
  )
}

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  verticalPosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  horizontalPosition: PropTypes.oneOf(['left', 'center', 'right']),
  autoHideDuration: PropTypes.number
}

Snackbar.defaultProps = {
  variant: 'info',
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  autoHideDuration: 6000
}

export default Snackbar
