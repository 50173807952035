import { merge } from 'lodash'

export const withMixinsVariants = theme => {
  return merge(theme, {
    mixins: {
      toolbar: {
        minHeight: theme.spacing(8),
        [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
          minHeight: theme.spacing(6)
        },
        [theme.breakpoints.up('sm')]: {
          minHeight: theme.spacing(10)
        }
      }
    }
  })
}
