import React from 'react'
import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { formatDistanceStrictShort } from '@doinn/shared/src/util/date-fns'
import Icon from '@doinn/shared/src/components/common/Icon'
import {
  getNotificationSubheaderLabel,
  getNotificationText
} from '@doinn/shared/src/containers/notifications/util'
import NotificationsListItemLoading from '@doinn/shared/src/containers/notifications/ListItemLoading'

const useStyles = makeStyles(theme => ({
  subheader: {
    padding: theme.spacing(2, 0, 1, 0),
    position: 'relative'
  },
  subheaderTransparent: {
    opacity: 0.7
  },
  readMarkerContent: {
    margin: theme.spacing(-0.5, -2, 0, -1),
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  item: {
    padding: theme.spacing(2)
  },
  itemContent: {
    marginTop: 0,
    marginBottom: 0
  },
  textItemNotRead: {
    fontWeight: 'bold'
  }
}))

const NotificationsListItem = ({
  index,
  isLoaded,
  isFirstVisible,
  item,
  onClick,
  previousItem
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isFirstItem = index === 0
  const isNotFirstItem = !isFirstItem
  const currentSubheader =
    item && item.createdAt
      ? getNotificationSubheaderLabel(item.createdAt, t)
      : null
  const previousSubheader =
    isNotFirstItem && previousItem && previousItem.createdAt
      ? getNotificationSubheaderLabel(previousItem.createdAt, t)
      : null
  const hasSubheader = isNotFirstItem && currentSubheader !== previousSubheader
  const hasDivider = isNotFirstItem && !hasSubheader
  const notificationText = getNotificationText(item, t)
  const textClassName = clsx('', {
    [classes.textItemNotRead]: item && !item.isRead
  })

  if (!isLoaded) {
    return (
      <div>
        {isNotFirstItem ? <Divider variant='middle' /> : null}
        <Box px={2}>
          <NotificationsListItemLoading />
        </Box>
      </div>
    )
  }

  return (
    <div>
      {hasSubheader ? (
        <Box px={2}>
          <ListSubheader
            component='div'
            className={clsx(classes.subheader, {
              [classes.subheaderTransparent]: isFirstVisible
            })}
          >
            {currentSubheader}
          </ListSubheader>
        </Box>
      ) : null}
      {hasDivider ? <Divider variant='middle' /> : null}
      <ListItem
        component='div'
        button
        onClick={onClick}
        className={classes.item}
      >
        <ListItemText
          disableTypography
          primary={
            <Grid container justify='space-between' wrap='nowrap' spacing={2}>
              <Grid item>
                <div className={classes.readMarkerContent}>
                  {!item.isRead ? (
                    <Icon icon='bullet_medium' color='primary' />
                  ) : null}
                </div>
              </Grid>
              <Grid item xs>
                <Typography className={textClassName}>
                  {notificationText}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='body2' className={textClassName}>
                  {formatDistanceStrictShort(
                    new Date(item.createdAt),
                    new Date()
                  )}
                </Typography>
              </Grid>
            </Grid>
          }
          className={classes.itemContent}
        />
      </ListItem>
    </div>
  )
}

export default NotificationsListItem
