import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import Avatar from '@doinn/shared/src/components/common/Avatar'

const getNameInitials = name => {
  if (!name) return ''
  const parsedName = name.trim().toUpperCase()
  const names = parsedName.split(' ')
  if (names.length === 1) {
    return parsedName.substring(0, 2)
  }
  const firstNameInitial = names[0].substring(0, 1)
  const lastNameInitial = names[names.length - 1].substring(0, 1)
  return `${firstNameInitial}${lastNameInitial}`
}

const useStyles = makeStyles(theme => ({
  small: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: theme.typography.body1.fontSize,
    display: 'inline-flex'
  }
}))

const UserAvatar = ({ user, size, className, ...otherProps }) => {
  const classes = useStyles()

  if (!user) return null

  const fullName = user.name || ''
  const email = user.email || ''
  const image = user.image || user.profile_image || user.profileImage || ''

  const nameInitials = getNameInitials(fullName)
  const avatarProps = {
    seed: email,
    image: image,
    alt: fullName
  }

  const computedClassName = clsx(className, {
    [classes.small]: size === 'small'
  })

  return (
    <Avatar {...avatarProps} {...otherProps} className={computedClassName}>
      {nameInitials}
    </Avatar>
  )
}

UserAvatar.propsTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    profile_image: PropTypes.string,
    profileImage: PropTypes.string
  }),
  size: PropTypes.oneOf(['small'])
}

export default React.memo(UserAvatar)
