import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import {
  Dialog as MaterialDialog,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'

const useStyles = makeStyles(theme => {
  const mobileBreakpoint = theme.breakpoints.down('xs')

  return {
    modal: props => {
      const { isApp } = props
      const modalStyles = {}

      if (isApp) {
        modalStyles[mobileBreakpoint] = {
          paddingTop: `env(safe-area-inset-top, 0)`
        }
      }

      return modalStyles
    }
  }
})

const Dialog = ({ children, title, ...other }) => {
  const dialogProps = { ...other }
  const isApp = useAppDetector()
  const classes = useStyles({ isApp })
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <MaterialDialog
      fullScreen={fullScreen}
      {...dialogProps}
      className={classes.modal}
    >
      {children}
    </MaterialDialog>
  )
}

Dialog.propTypes = {
  disableBackdropClick: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

Dialog.muiName = 'Dialog'

Dialog.defaultProps = {
  open: false,
  fullWidth: true,
  maxWidth: 'sm',
  scroll: 'paper',
  disableBackdropClick: true
}

export default compose()(Dialog)
