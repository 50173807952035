import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  hideSnackbar,
  resetSnackbar
} from '@doinn/shared/src/containers/snackbar/actions'
import SnackbarComponent from '@doinn/shared/src/containers/snackbar/components/Snackbar'

class Snackbar extends Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.props.hideSnackbar()
  }

  handleExited = () => {
    this.props.resetSnackbar()
  }

  render() {
    const {
      show,
      message,
      variant,
      verticalPosition,
      horizontalPosition,
      autoHideDuration
    } = this.props
    return (
      <SnackbarComponent
        onExited={this.handleExited}
        onClose={this.handleClose}
        show={show}
        message={message}
        variant={variant}
        verticalPosition={verticalPosition}
        horizontalPosition={horizontalPosition}
        autoHideDuration={autoHideDuration}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    show: state.snackbar.show,
    message: state.snackbar.message,
    variant: state.snackbar.variant,
    verticalPosition: state.snackbar.verticalPosition,
    horizontalPosition: state.snackbar.horizontalPosition,
    autoHideDuration: state.snackbar.autoHideDuration
  }
}

const mapDispatchToProps = dispatch => ({
  hideSnackbar: bindActionCreators(hideSnackbar, dispatch),
  resetSnackbar: bindActionCreators(resetSnackbar, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar)
