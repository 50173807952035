import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchPropertyTypesApi(params = {}) {
  const { flags, userRole, ...otherParams } = params
  const config = {
    params: otherParams
  }
  const parsedFlags = flags ? `?${flags.join('&')}` : ''
  if (userRole === 'vendor') {
    return DoinnApi.get(`/vendor/space-categories${parsedFlags}`, config)
  }
  if (userRole === 'host') {
    return DoinnApi.get(`/host/properties/categories${parsedFlags}`, config)
  }
  if (userRole === 'admin') {
    return DoinnApi.get(`/properties/categories${parsedFlags}`, config)
  }
}
