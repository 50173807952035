import {
  HIDE_SNACKBAR,
  RESET_SNACKBAR,
  SHOW_SNACKBAR
} from '@doinn/shared/src/containers/snackbar/constants'

export const initialState = {
  show: false,
  message: '',
  variant: 'info',
  verticalPosition: 'bottom',
  horizontalPosition: 'center',
  autoHideDuration: 6000
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SHOW_SNACKBAR:
      return {
        ...payload,
        show: true
      }
    case HIDE_SNACKBAR:
      return {
        ...state,
        show: false
      }
    case RESET_SNACKBAR:
      return {
        ...initialState
      }
    default:
      return state
  }
}
