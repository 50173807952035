import React from 'react'
import { compose } from 'redux'
import { Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import FatalError from 'scenes/FatalError'

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
    // TODO: a Sentry, or similar, log could be called here
  }

  render() {
    // TODO: don't return it there when in development environment
    // If Route failed with any error
    // return a failed route so user can go back
    if (this.state.hasError) {
      return (
        <Route
          path={this.props.path}
          exact={this.props.exact}
          component={FatalError}
        />
      )
    }

    // TODO: return it when not in development environment
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>{this.props.t('Something went wrong!')}</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      )
    }

    // Normally, just render children
    return this.props.children
  }
}

export default compose(withTranslation())(AppErrorBoundary)
