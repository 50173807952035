import React from 'react'
import { get } from 'lodash'
import { subDays } from 'date-fns'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { ListSubheader, makeStyles } from '@material-ui/core'
import { useDidMount } from '@doinn/shared/src/hooks'
import {
  formatSystemDate,
  formatSystemDateTime
} from '@doinn/shared/src/util/date-fns'
import {
  checkNotificationAsRead,
  fetchMoreNotifications,
  fetchNotifications
} from '@doinn/shared/src/containers/notifications/actions'
import { STATUS } from '@doinn/shared/src/containers/notifications/reducers'
import VirtualList from '@doinn/shared/src/components/common/virtual-list/VirtualList'
import NotificationsListItem from '@doinn/shared/src/containers/notifications/ListItem'
import NotificationsListLoading from '@doinn/shared/src/containers/notifications/ListLoading'
import { getNotificationSubheaderLabel } from '@doinn/shared/src/containers/notifications/util'
import { getQueryString } from '@doinn/shared/src/util/url'

const DEFAULT_FIRST_PAGE = 1
const DEFAULT_ROWS_PER_PAGE = 30
const DEFAULT_MAX_DAYS_TO_FETCH = 30

const useStyles = makeStyles(theme => {
  return {
    header: {
      background: theme.palette.background.paper,
      padding: theme.spacing(2, 2, 1, 2),
      margin: theme.spacing(0, -2, 2, -2),
      position: 'sticky',
      top: 0
    }
  }
})

const NotificationList = ({
  fetchNotifications,
  fetchMoreNotifications,
  history,
  isNotificationsLoading,
  isNotificationsNextPageLoading,
  notifications,
  checkNotificationAsRead,
  meta,
  onClickItem
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [visibleIndex, setVisibleIndex] = React.useState(0)
  const [to] = React.useState(formatSystemDateTime(new Date(), 'full'))
  const [from] = React.useState(
    formatSystemDateTime(
      subDays(new Date(to), DEFAULT_MAX_DAYS_TO_FETCH),
      'full'
    )
  )

  useDidMount(() => {
    if (!isNotificationsLoading) {
      fetchNotifications({
        params: { from, to, page: DEFAULT_FIRST_PAGE }
      })
    }
  })

  let totalItems = 0
  let page = 0
  let rowsPerPage = DEFAULT_ROWS_PER_PAGE
  let hasNextPage = false
  try {
    totalItems = meta.pagination.total
    page = Math.max(DEFAULT_FIRST_PAGE, meta.pagination.currentPage)
    rowsPerPage = meta.pagination.perPage
    hasNextPage = notifications.length < totalItems
  } catch (e) {}

  const handleItemsRendered = ({ visibleStartIndex }) => {
    setVisibleIndex(visibleStartIndex)
  }

  const handleRedirect = item => {
    if (!item || !item.type) {
      return
    }

    // TODO: remove duplicated logic
    const redirectParams = {
      'App\\Notifications\\OrderServiceNoteCreation': {
        pathname: '/services',
        params: {
          orderServiceId: get(item, 'data.orderService.id'),
          jobId: get(item, 'data.orderService.vendorJob.id')
        }
      },
      'App\\Notifications\\JobFinishNoteSent': {
        pathname: '/services',
        params: {
          jobId: get(item, 'data.id')
        }
      },
      'App\\Notifications\\JobScheduleUpdated': {
        pathname: '/services',
        params: {
          jobId: get(item, 'data.id')
        }
      },
      'App\\Notifications\\NextDayServicesSent': {
        pathname: '/services',
        params: {
          period: 'custom',
          start: formatSystemDate(item?.data?.datetime ?? new Date(), 'medium'),
          end: formatSystemDate(item?.data?.datetime ?? new Date(), 'medium')
        }
      }
    }

    redirectParams['Doinn\\Notifications\\OrderServiceNoteCreation'] =
      redirectParams['App\\Notifications\\OrderServiceNoteCreation']
    redirectParams['App\\Notifications\\NoteCreation'] =
      redirectParams['App\\Notifications\\OrderServiceNoteCreation']

    if (redirectParams[item.type]) {
      const { pathname, params } = redirectParams[item.type]
      const newSearchQueryString = getQueryString(params)
      history.push({
        pathname,
        search: newSearchQueryString
      })
    }
  }

  const handleClickItem = item => {
    if (!item) return
    !item.isRead && checkNotificationAsRead(item.id)
    onClickItem(item)
    handleRedirect(item)
  }

  const handleLoadNextPage = (startIndex, stopIndex) => {
    if (isNotificationsLoading || isNotificationsNextPageLoading) return
    fetchMoreNotifications({
      params: { from, to, page: page + 1, limit: rowsPerPage }
    })
  }

  const headerRenderer = React.useCallback(() => {
    if (!notifications || !notifications[visibleIndex]) return null
    const notificationDate = notifications[visibleIndex].createdAt
    if (!notificationDate) return null
    const header = getNotificationSubheaderLabel(notificationDate, t)
    return (
      <ListSubheader component='div' className={classes.header}>
        {header}
      </ListSubheader>
    )
  }, [notifications, visibleIndex, t, classes.header])

  return (
    <React.Fragment>
      {headerRenderer()}
      <VirtualList
        isLoading={isNotificationsLoading}
        items={notifications}
        gutter={0}
        estimatedItemHeight={6}
        onItemClick={handleClickItem}
        onItemsRendered={handleItemsRendered}
        ItemComponent={NotificationsListItem}
        LoadingItemComponent={NotificationsListLoading}
        onLoadNextPage={handleLoadNextPage}
        hasNextPage={hasNextPage}
        isNextPageLoading={isNotificationsNextPageLoading}
        disableFooterHeight
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const {
    notifications,
    meta,
    status: notificationsListStatus
  } = state.notifications

  return {
    notifications,
    meta,
    isNotificationsLoading: notificationsListStatus === STATUS.LOADING,
    isNotificationsNextPageLoading:
      notificationsListStatus === STATUS.LOADING_MORE
  }
}

const mapDispatchToProps = dispatch => ({
  fetchNotifications: bindActionCreators(fetchNotifications, dispatch),
  fetchMoreNotifications: bindActionCreators(fetchMoreNotifications, dispatch),
  checkNotificationAsRead: bindActionCreators(checkNotificationAsRead, dispatch)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(NotificationList)
