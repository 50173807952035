import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { toArray } from 'lodash'
import { appRoutes, siteRoutes } from 'routes'
import { start } from '@doinn/shared/src/containers/app/actions'
import AppRedirects from 'containers/app/AppRedirects'
import AppLoading from '@doinn/shared/src/components/app/AppLoading'
import SnackbarContainer from '@doinn/shared/src/containers/snackbar/Snackbar'
import withAppDetector from '@doinn/shared/src/hoc/appDetector/withAppDetector'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'
import AppErrorBoundary from '@doinn/shared/src/components/app/AppErrorBoundary'
import NewReleaseNotification from '@doinn/shared/src/components/app/NewReleaseNotification'
import NotificationCounterListener from '@doinn/shared/src/containers/notifications/NotificationCounterListener'
import { showOrHideIntercom } from '@doinn/shared/src/util/intercom'

class App extends React.Component {
  componentDidMount() {
    this.props.onStart()
    showOrHideIntercom()
  }

  componentDidUpdate(prevProps) {
    showOrHideIntercom()
  }

  render() {
    const { loggedUser, isApp, isLoadingApp, isLoadingUser } = this.props
    const isLoading = isLoadingApp || isLoadingUser
    let routes = isApp ? appRoutes : siteRoutes
    routes = toArray(routes).filter(
      route =>
        route.allowedPermissions === undefined ||
        this.props.canAll(route.allowedPermissions)
    )

    if (isLoading) {
      return <AppLoading />
    }

    return (
      <TemplateBlank>
        <Switch>
          {routes.map(route => (
            <AppErrorBoundary {...route} key={`${route.key}-bond`}>
              <Route
                key={route.key}
                path={route.path}
                component={route.component(!!loggedUser)}
                exact={!!route.exact}
              />
            </AppErrorBoundary>
          ))}
        </Switch>
        <AppRedirects />
        <SnackbarContainer />
        <NewReleaseNotification />
        <NotificationCounterListener />
      </TemplateBlank>
    )
  }
}

const mapStateToProps = state => ({
  isLoadingApp: state.app.isLoadingApp,
  isLoadingUser: state.user.isLoadingLoggedUser,
  loggedUser: state.user.loggedUser
})

const mapDispatchToProps = dispatch => ({
  onStart: bindActionCreators(start, dispatch)
})

export default compose(
  withAppDetector,
  connect(mapStateToProps, mapDispatchToProps)
)(App)
