import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as searchReducer } from 'redux-search'
import appReducers from '@doinn/shared/src/containers/app/reducers'
import authReducers from '@doinn/shared/src/containers/auth/reducers'
import chatsReducers from '@doinn/shared/src/containers/chats/reducers'
import chatsContactsReducers from '@doinn/shared/src/containers/chats/contacts/reducers'
import chatsJobsReducers from '@doinn/shared/src/containers/chats/jobs/reducers'
import chatsPropertiesReducers from '@doinn/shared/src/containers/chats/properties/reducers'
import chatAddReducers from '@doinn/shared/src/containers/chats/add/reducers'
import chatFollowReducers from '@doinn/shared/src/containers/chats/follow/reducers'
import chatGetReducers from '@doinn/shared/src/containers/chats/get/reducers'
import chatMessagesReducers from '@doinn/shared/src/containers/chats/messages/reducers'
import cityListReducers from '@doinn/shared/src/containers/city/list/reducers'
import propertyTypesReducers from '@doinn/shared/src/containers/property-types/reducers'
import userReducers from '@doinn/shared/src/containers/user/reducers'
import languageReducers from '@doinn/shared/src/containers/language/reducers'
import notificationsReducers from '@doinn/shared/src/containers/notifications/reducers'
import snackbarReducers from '@doinn/shared/src/containers/snackbar/reducers'

const createRootReducer = history =>
  combineReducers({
    search: searchReducer,
    router: connectRouter(history),
    app: appReducers,
    language: languageReducers,
    auth: authReducers,
    chats: chatsReducers,
    chatsContacts: chatsContactsReducers,
    chatsJobs: chatsJobsReducers,
    chatsProperties: chatsPropertiesReducers,
    chatAdd: chatAddReducers,
    chatFollow: chatFollowReducers,
    chatGet: chatGetReducers,
    chatMessages: chatMessagesReducers,
    cityList: cityListReducers,
    notifications: notificationsReducers,
    propertyTypes: propertyTypesReducers,
    user: userReducers,
    snackbar: snackbarReducers
  })

export default createRootReducer
