import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  APP_SET_LANGUAGE_FAILED,
  APP_SET_LANGUAGE_REQUESTED,
  APP_SET_LANGUAGE_SUCCEEDED
} from '@doinn/shared/src/containers/language/constants'

function setLanguage(defaultLanguage, onChange) {
  const setAppLanguageApi = (params = {}) => {
    const { language } = params
    const newLanguage = language || defaultLanguage
    const lang = newLanguage.slice(0, 2)

    try {
      window.__localeId__ = lang
    } catch (e) {
      console.error(e)
    }

    try {
      document.documentElement.lang = lang
    } catch (e) {
      console.error(e)
    }

    onChange(lang)
  }

  return function* (action) {
    try {
      yield call(setAppLanguageApi, action.payload.params)
      yield put({
        type: APP_SET_LANGUAGE_SUCCEEDED,
        payload: action.payload.params
      })
    } catch (e) {
      yield put({
        type: APP_SET_LANGUAGE_FAILED,
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

export default function* appLanguageSaga(defaultLanguage, onChange) {
  yield all([
    takeLatest(
      APP_SET_LANGUAGE_REQUESTED,
      setLanguage(defaultLanguage, onChange)
    )
  ])
}
