export const ECHO_EVENT_ORDER_SERVICE_EXECUTION_STATUS_UPDATE =
  '.order-service.execution-status.update'
export const ECHO_EVENT_NOTIFICATIONS_COUNTER = '.notifications.counter'
export const ECHO_EVENT_CHAT_ADD = '.chat.add'
export const ECHO_EVENT_CHAT_UPDATE = '.chat.update'
export const ECHO_EVENT_CHAT_MESSAGE_SEND = '.chat-message.send'
export const ECHO_EVENT_CHAT_UNREAD_MESSAGES_COUNTER =
  '.chat.unread-messages.counter'
export const ECHO_EVENT_INTEGRATION_SYNC_PROPERTIES_COMPLETED =
  '.integration-sync.spaces.completed'
export const ECHO_EVENT_IMPORT_STATUS_UPDATED = '.import.status.updated'
