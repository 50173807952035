import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import TemplateAuth from '@doinn/shared/src/components/templates/TemplateAuth'
import LoginContainer from '@doinn/shared/src/containers/auth/Login'

const Login = () => {
  const { t } = useTranslation()

  return (
    <TemplateAuth title={t('Sign in')}>
      <Helmet>
        <title>{t('Sign in')}</title>
      </Helmet>
      <LoginContainer />
    </TemplateAuth>
  )
}

export default Login
