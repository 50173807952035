import {
  NOTIFICATIONS_COUNTER_SETTED,
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATIONS_FETCH_MORE_FAILED,
  NOTIFICATIONS_FETCH_MORE_REQUESTED,
  NOTIFICATIONS_FETCH_MORE_SUCCEEDED,
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATIONS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/notifications/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  counter: 0,
  notifications: [],
  meta: {},
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case NOTIFICATIONS_FETCH_REQUESTED:
      return {
        ...state,
        notifications: [],
        status: STATUS.LOADING
      }
    case NOTIFICATIONS_FETCH_SUCCEEDED:
      return {
        ...state,
        notifications: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case NOTIFICATIONS_FETCH_FAILED:
      return {
        ...state,
        ...initialState,
        status: STATUS.FAILURE
      }
    case NOTIFICATIONS_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case NOTIFICATIONS_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        notifications: [...state.notifications, ...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case NOTIFICATIONS_FETCH_MORE_FAILED:
      return {
        ...state,
        ...initialState,
        status: STATUS.FAILURE
      }
    case NOTIFICATIONS_COUNTER_SETTED:
      return {
        ...state,
        counter: payload.data
      }
    default:
      return state
  }
}
