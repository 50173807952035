import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import Icon from '@doinn/shared/src/components/common/Icon'

const useStyles = makeStyles(theme => ({
  closeButton: {
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-2)
  }
}))

const HeaderCloseButton = ({ onClose }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  if (!onClose) return null

  return (
    <Grid container direction='row' justify='flex-end' alignItems='center'>
      <IconButton
        key='close'
        aria-label={t('close')}
        color='inherit'
        className={classes.closeButton}
        onClick={onClose}
      >
        <Icon icon='close' fontSize='large' />
      </IconButton>
    </Grid>
  )
}

HeaderCloseButton.propTypes = {
  onClose: PropTypes.func
}

HeaderCloseButton.defaultProps = {}

export default HeaderCloseButton
