import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchNotificationsApi(params) {
  const config = {
    ...params
  }
  return DoinnApi.get(`/notifications`, config)
}

export function checkNotificationAsReadApi(id) {
  const config = {}
  return DoinnApi.patch(`/notifications/${id}/read`, config)
}
