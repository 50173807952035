import React, { useEffect } from 'react'
import ReactGA from 'react-ga'

export default (WrappedComponent, GOOGLE_ANALYTICS, options = {}) => {
  ReactGA.initialize(GOOGLE_ANALYTICS, { redactEmail: false })

  const trackPage = location => {
    const { pathname, search } = location
    const url = pathname + search

    ReactGA.set({ page: url, ...options })
    ReactGA.pageview(url)
  }

  const HOC = props => {
    useEffect(() => trackPage(props.location), [props.location])

    return <WrappedComponent {...props} />
  }

  return HOC
}
