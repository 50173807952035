import { isToday } from 'date-fns'
import {
  convertFromMilitaryTime,
  formatDate
} from '@doinn/shared/src/util/date-fns'

export const getNotificationSubheaderLabel = (dateString, t) => {
  if (!dateString || !t) return
  return isToday(new Date(dateString)) ? t('Recent') : t('Earlier')
}

export const getNotificationTextOrderServiceNote = (noteData, t) => {
  if (!noteData) return ''
  try {
    const orderService = noteData.orderService
    const serviceDateTime = orderService.dateTime || orderService.datetime
    const datetimeString = `${serviceDateTime.date} ${convertFromMilitaryTime(
      serviceDateTime.startTime
    )}`
    const translationParams = {
      propertyName: orderService.space.name,
      date: formatDate(datetimeString, 'longDmWeek')
    }
    switch (noteData.category) {
      case 'missing':
        return t(
          'A time note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      case 'issue':
        return t(
          'An alert note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      case 'damage':
        return t(
          'A maintenance was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      case 'simple':
        return t(
          'A general note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
      default:
        return t(
          'A general note was added to your service on {{date}}, for the {{propertyName}} property',
          translationParams
        )
    }
  } catch (e) {
    return t('A note was added to your service')
  }
}

export const getNotificationTextJobFinishingNote = (job, t) => {
  const translationParams = {
    serviceName: job?.title,
    propertyName: job?.space?.name
  }

  return t(
    'A job summary for the {{serviceName}} service at {{propertyName}} property is available',
    translationParams
  )
}

export const getNotificationTextJobScheduleUpdated = (job, t) => {
  const translationParams = {
    serviceName: job?.title,
    propertyName: job?.space?.name
  }

  return t(
    'The estimated time has been changed for the {{serviceName}} service at {{propertyName}}.',
    translationParams
  )
}

export const getNotificationTextNextDayServices = (data, t) => {
  if (!data?.datetime) {
    return ''
  }

  const translationParams = {
    date: formatDate(data.datetime, 'long')
  }

  return t("Tomorrow's services reminder for {{date}}.", translationParams)
}

export const getNotificationText = (item, t) => {
  if (!item || !item.type || !t) return ''
  switch (item.type) {
    case 'App\\Notifications\\OrderServiceNoteCreation':
    case 'Doinn\\Notifications\\OrderServiceNoteCreation':
    case 'App\\Notifications\\NoteCreation':
      return getNotificationTextOrderServiceNote(item.data, t)
    case 'App\\Notifications\\JobFinishNoteSent':
      return getNotificationTextJobFinishingNote(item.data, t)
    case 'App\\Notifications\\JobScheduleUpdated':
      return getNotificationTextJobScheduleUpdated(item.data, t)
    case 'App\\Notifications\\NextDayServicesSent':
      return getNotificationTextNextDayServices(item.data, t)
    default:
      return ''
  }
}
