import DoinnApi from '@doinn/shared/src/config/doinnApi'

export function fetchCitiesApi(params = {}) {
  const { flags, userRole, ...otherParams } = params
  const config = {
    params: otherParams
  }
  const parsedFlags = flags ? `?${flags.join('&')}` : ''
  if (userRole === 'admin') {
    return DoinnApi.get(`/cities${parsedFlags}`, config)
  }
  if (userRole === 'vendor') {
    return DoinnApi.get(`/vendor/cities${parsedFlags}`, config)
  }
  return DoinnApi.get(`/host/cities${parsedFlags}`, config)
}
