import { useCallback, useEffect, useState } from 'react'
import { usePrevious } from '@doinn/shared/src/hooks'

/**
 * @param {boolean} [initialOpen=false]
 * @returns {Object}
 */
const useOpenState = (initialOpen = false) => {
  const [open, setOpen] = useState(initialOpen)
  const [justOpened, setJustOpened] = useState(false)
  const [justClosed, setJustClosed] = useState(false)
  const previousOpen = usePrevious(open)

  useEffect(() => {
    setJustClosed(previousOpen && !open)
    setJustOpened(!previousOpen && open)
  }, [open, previousOpen])

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  return {
    justClosed,
    justOpened,
    onClose,
    onOpen,
    open,
    setOpen
  }
}

export default useOpenState
