import * as Sentry from '@sentry/capacitor'

export const init = (environment, dsn) => {
  if (environment !== 'local') {
    Sentry.init({
      dsn,
      environment
    })
  }
}

export const setUser = user => Sentry.setUser(user)
