import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchPropertyTypesApi } from '@doinn/shared/src/api/property-types'
import {
  PROPERTY_TYPES_FETCH_FAILED,
  PROPERTY_TYPES_FETCH_REQUESTED,
  PROPERTY_TYPES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/property-types/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchPropertyTypes(action) {
  try {
    const response = yield call(fetchPropertyTypesApi, action.payload.params)
    yield put({
      type: PROPERTY_TYPES_FETCH_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: PROPERTY_TYPES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* propertyTypesSaga() {
  yield all([takeLatest(PROPERTY_TYPES_FETCH_REQUESTED, fetchPropertyTypes)])
}
