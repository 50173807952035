import React from 'react'
import { MOBILE_ONLY } from 'config/env'
import { Capacitor } from '@capacitor/core'

const appDisplayMode = '(display-mode: standalone)'
export const isInStandaloneMode = () => {
  const matcheStandalone =
    window.matchMedia && window.matchMedia(appDisplayMode)?.matches

  return (
    matcheStandalone ||
    window?.navigator?.standalone ||
    document?.referrer?.includes('android-app://')
  )
}

const useAppDetector = () => {
  const [isStandaloneMode, setIsStandaloneMode] = React.useState(
    isInStandaloneMode()
  )

  React.useEffect(() => {
    const media = window.matchMedia && window.matchMedia(appDisplayMode)

    if (!media || isInStandaloneMode()) {
      return
    }

    if (media.matches !== isStandaloneMode) {
      setIsStandaloneMode(media.matches)
    }

    const listener = () => setIsStandaloneMode(media.matches)

    if (media.addEventListener && media.removeEventListener) {
      media.addEventListener('change', listener)
      return () => media.removeEventListener('change', listener)
    }

    media.addListener(listener)
    return () => media.removeListener(listener)
  }, [isStandaloneMode])

  if (MOBILE_ONLY) {
    return true
  }

  if (Capacitor.isNativePlatform()) {
    return true
  }

  return isStandaloneMode
}

export default useAppDetector
