import { get } from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as errorTracker from '@doinn/shared/src/config/errorTracker'
import * as intercomMobile from '@doinn/shared/src/config/intercomMobile'
import * as segmentAnalytics from '@doinn/shared/src/config/segmentAnalytics'
import { getLoggedUserApi } from '@doinn/shared/src/api/user'
import { authLogoutApi } from '@doinn/shared/src/api/auth'
import {
  USER_GET_FAILED,
  USER_GET_REQUESTED,
  USER_GET_SUCCEEDED,
  USER_LOGOUT_FAILED,
  USER_LOGOUT_REQUESTED,
  USER_LOGOUT_SUCCEEDED
} from '@doinn/shared/src/containers/user/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import { setLanguage } from '@doinn/shared/src/containers/language/actions'
import { setNotificationsCounter } from '@doinn/shared/src/containers/notifications/actions'
import { updateUnreadMessagesCounter } from '@doinn/shared/src/containers/chats/actions'
import { resetAppStorage } from '@doinn/shared/src/containers/app/sagas'

function* getLoggedUser(action) {
  try {
    const response = yield call(getLoggedUserApi)
    const responseData = response.data
    const user = responseData.data

    segmentAnalytics.init(user)
    intercomMobile.init(user)
    errorTracker.setUser(user)

    yield put(setLanguage({ language: get(user, 'preferences.language') }))
    yield put(setNotificationsCounter(get(user, 'newNotifications')))
    yield put(updateUnreadMessagesCounter(get(user, 'totalUnreadMessages')))
    yield put({
      type: USER_GET_SUCCEEDED,
      payload: responseData
    })
  } catch (e) {
    yield put({
      type: USER_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* logoutUser(action) {
  try {
    const response = yield call(authLogoutApi)
    const responseData = response.data
    if (responseData.data.success) {
      yield resetAppStorage()
      yield put({ type: USER_LOGOUT_SUCCEEDED })
    }
  } catch (e) {
    yield put({
      type: USER_LOGOUT_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(USER_GET_REQUESTED, getLoggedUser),
    takeLatest(USER_LOGOUT_REQUESTED, logoutUser)
  ])
}
