import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  fetchChatMessagesApi,
  markChatAsReadApi,
  sendChatMessageApi
} from '@doinn/shared/src/api/chats'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  CHAT_MESSAGES_FETCH_FAILED,
  CHAT_MESSAGES_FETCH_MORE_FAILED,
  CHAT_MESSAGES_FETCH_MORE_REQUESTED,
  CHAT_MESSAGES_FETCH_MORE_SUCCEEDED,
  CHAT_MESSAGES_FETCH_REQUESTED,
  CHAT_MESSAGES_FETCH_SUCCEEDED,
  CHAT_MESSAGES_MARK_AS_READ_FAILED,
  CHAT_MESSAGES_MARK_AS_READ_REQUESTED,
  CHAT_MESSAGES_MARK_AS_READ_SUCCEEDED,
  CHAT_MESSAGES_RESEND_REQUESTED,
  CHAT_MESSAGES_SEND_FAILED,
  CHAT_MESSAGES_SEND_REQUESTED,
  CHAT_MESSAGES_SEND_SUCCEEDED
} from '@doinn/shared/src/containers/chats/messages/constants'

function* fetchChatMessages(action) {
  try {
    const { chat, params } = action.payload
    const response = yield call(fetchChatMessagesApi, chat, params)
    const { data, meta } = response.data
    yield put({
      type: CHAT_MESSAGES_FETCH_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHAT_MESSAGES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreChatMessages(action) {
  try {
    const { chat, params } = action.payload
    const response = yield call(fetchChatMessagesApi, chat, params)
    const { data, meta } = response.data
    yield put({
      type: CHAT_MESSAGES_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHAT_MESSAGES_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* sendChatMessages(action) {
  try {
    const { chat, message } = action.payload
    const response = yield call(sendChatMessageApi, chat, message)
    const { data, meta } = response.data
    yield put({
      type: CHAT_MESSAGES_SEND_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHAT_MESSAGES_SEND_FAILED,
      payload: action.payload,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* markChatAsRead(action) {
  try {
    const response = yield call(markChatAsReadApi, action.payload.chat)
    const chatsResponse = response.data
    yield put({
      type: CHAT_MESSAGES_MARK_AS_READ_SUCCEEDED,
      payload: chatsResponse
    })
  } catch (e) {
    yield put({
      type: CHAT_MESSAGES_MARK_AS_READ_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* chatMessagesSagas() {
  yield all([
    takeLatest(CHAT_MESSAGES_FETCH_REQUESTED, fetchChatMessages),
    takeLatest(CHAT_MESSAGES_FETCH_MORE_REQUESTED, fetchMoreChatMessages),
    takeEvery(CHAT_MESSAGES_SEND_REQUESTED, sendChatMessages),
    takeEvery(CHAT_MESSAGES_RESEND_REQUESTED, sendChatMessages),
    takeLatest(CHAT_MESSAGES_MARK_AS_READ_REQUESTED, markChatAsRead)
  ])
}
