import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@material-ui/core'
import TemplateDefault from 'components/TemplateDefault'

const FatalError = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault>
      <Helmet>
        <title>{t('Something went wrong!')}</title>
      </Helmet>
      <Box pt={20}>
        <Typography component='p' align='center' variant='subtitle1'>
          {t("Whoops, we didn't find the section you're looking for.")}
        </Typography>
        <Typography component='p' align='center' variant='subtitle1'>
          {t(
            'You can return to the previous section, visit another section or contact our support team.'
          )}
        </Typography>
      </Box>
    </TemplateDefault>
  )
}

export default FatalError
