import { cloneDeep, some } from 'lodash'
import {
  CHATS_FETCH_FAILED,
  CHATS_FETCH_MORE_FAILED,
  CHATS_FETCH_MORE_REQUESTED,
  CHATS_FETCH_MORE_SUCCEEDED,
  CHATS_FETCH_REQUESTED,
  CHATS_FETCH_SUCCEEDED,
  CHAT_ADD_SUCCEEDED,
  CHAT_UPDATE_SUCCEEDED,
  CHAT_UPDATE_UNREAD_MESSAGES_COUNTER
} from '@doinn/shared/src/containers/chats/constants'
import { CHAT_ADD_FORM_SAVE_SUCCEEDED } from '@doinn/shared/src/containers/chats/add/constants'
import {
  CHAT_MESSAGES_MARK_AS_READ_SUCCEEDED,
  CHAT_MESSAGES_NEW_MESSAGE_RECEIVED,
  CHAT_MESSAGES_SELECTED_CHAT_UPDATED
} from '@doinn/shared/src/containers/chats/messages/constants'

export const STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADING_MORE: 'loading-more',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  selectedChat: null,
  list: [],
  meta: {},
  status: STATUS.IDLE,
  unreadMessageCounter: 0
}

const parseReadChatToState = (state, payload) => {
  const newState = cloneDeep(state)
  const chat = payload.data
  newState.list = newState.list.map(item => {
    if (item.id === chat.id) {
      return {
        ...item,
        unread: chat.unread,
        participants: cloneDeep(chat.participants),
        lastMessage: cloneDeep(chat.lastMessage)
      }
    }
    return item
  })
  return newState
}

const parseNewMessagesReceivedToState = (state, payload) => {
  const newState = cloneDeep(state)
  const message = payload.message
  const previousChatIndex = newState.list.findIndex(
    chat => chat.id === message.chat.id
  )
  if (previousChatIndex > -1) {
    const previousChat = newState.list[previousChatIndex]
    const newUnread =
      previousChat.unread +
      parseInt(
        state.selectedChat && message.chat.id === state.selectedChat.id ? 0 : 1,
        10
      )
    const newChat = cloneDeep({
      ...previousChat,
      unread: newUnread,
      lastMessage: cloneDeep(message)
    })
    newState.list.splice(previousChatIndex, 1)
    newState.list = [newChat, ...newState.list]
  }
  return newState
}

const parseUpdatedChatToState = (state, payload) => {
  const newState = cloneDeep(state)
  const chatIndex = newState.list.findIndex(chat => chat.id === payload.chat.id)

  if (chatIndex > -1) {
    newState.list[chatIndex] = payload.chat
  }

  return newState
}

const parseNewChatToState = (state, payload) => {
  if (some(state.list, chat => chat.id === payload.chat.id)) {
    return parseUpdatedChatToState(state, payload)
  }

  return { ...state, list: [payload.chat, ...state.list] }
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CHATS_FETCH_REQUESTED:
      return {
        ...state,
        list: initialState.list,
        meta: initialState.meta,
        status: STATUS.LOADING
      }
    case CHATS_FETCH_SUCCEEDED:
      return {
        ...state,
        list: [...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case CHATS_FETCH_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case CHATS_FETCH_MORE_REQUESTED:
      return {
        ...state,
        status: STATUS.LOADING_MORE
      }
    case CHATS_FETCH_MORE_SUCCEEDED:
      return {
        ...state,
        list: [...state.list, ...payload.data],
        meta: { ...payload.meta },
        status: STATUS.SUCCESS
      }
    case CHATS_FETCH_MORE_FAILED:
      return {
        ...state,
        status: STATUS.FAILURE
      }
    case CHAT_MESSAGES_MARK_AS_READ_SUCCEEDED:
      return {
        ...parseReadChatToState(state, payload)
      }
    case CHAT_ADD_FORM_SAVE_SUCCEEDED:
    case CHAT_ADD_SUCCEEDED:
      return parseNewChatToState(state, payload)
    case CHAT_UPDATE_SUCCEEDED:
      return parseUpdatedChatToState(state, payload)
    case CHAT_MESSAGES_NEW_MESSAGE_RECEIVED:
      return parseNewMessagesReceivedToState(state, payload)
    case CHAT_UPDATE_UNREAD_MESSAGES_COUNTER:
      return {
        ...state,
        unreadMessageCounter: payload.counter
      }
    case CHAT_MESSAGES_SELECTED_CHAT_UPDATED:
      return { ...state, selectedChat: payload.chat }
    default:
      return state
  }
}
