/* eslint-disable no-template-curly-in-string */
import { object, string } from 'yup'
import i18n from '@doinn/shared/src/config/i18n'

const MAX_LENGTH_EMAIL = 160
const MIN_LENGTH_PASSWORD = 3
const emailRule = string()
  .required(i18n.t('Required'))
  .email(i18n.t('Invalid email address'))
  .max(MAX_LENGTH_EMAIL, i18n.t('Must have ${max} characters or less'))

const getLoginValidationSchema = () =>
  object({
    email: emailRule,
    password: string()
      .required(i18n.t('Required'))
      .min(MIN_LENGTH_PASSWORD, i18n.t('Must have ${min} characters or more'))
  })

const getRecoverPasswordValidationSchema = () => object({ email: emailRule })

let loginValidationSchema = getLoginValidationSchema()
let recoverPasswordValidationSchema = getRecoverPasswordValidationSchema()

i18n.on('languageChanged init', () => {
  loginValidationSchema = getLoginValidationSchema()
  recoverPasswordValidationSchema = getRecoverPasswordValidationSchema()
})

export { loginValidationSchema, recoverPasswordValidationSchema }
