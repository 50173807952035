import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'

const AppLoading = props => {
  return (
    <TemplateBlank>
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        style={{
          position: 'fixed',
          height: '100%'
        }}
      >
        <Grid item>
          <CircularProgress color='primary' />
        </Grid>
      </Grid>
    </TemplateBlank>
  )
}

export default AppLoading
