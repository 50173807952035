import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import Icon from '@doinn/shared/src/components/common/Icon'
import UserAvatar from '@doinn/shared/src/components/common/UserAvatar'

const useStyles = makeStyles(theme => ({
  menu: {
    marginRight: theme.spacing(-1)
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  listItemAvatar: {
    minWidth: 'auto'
  },
  avatar: {
    marginRight: theme.spacing(1.5)
  },
  userName: {
    maxWidth: theme.spacing(20)
  },
  icon: {
    verticalAlign: 'text-bottom',
    marginLeft: theme.spacing(1.5)
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const HeaderUser = ({ loggedUser, onLogout }) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const isOpened = Boolean(anchorEl)
  const icon = isOpened ? 'chevron_up_circle' : 'chevron_down_circle_outline'

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!loggedUser) return null

  return (
    <div className={classes.menu}>
      <List
        disablePadding
        aria-controls='header-user-menu'
        aria-haspopup='true'
      >
        <ListItem
          button
          disableGutters
          className={classes.listItem}
          onClick={handleOpen}
        >
          <ListItemAvatar className={classes.listItemAvatar}>
            <UserAvatar
              user={loggedUser}
              className={isLargeDesktop ? classes.avatar : ''}
              size='small'
            />
          </ListItemAvatar>
          {isLargeDesktop ? (
            <ListItemText
              primary={
                <Grid alignItems='center' container wrap='nowrap'>
                  <Grid item zeroMinWidth className={classes.userName}>
                    <Typography variant='body2' noWrap>
                      {loggedUser.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Icon icon={icon} className={classes.icon} />
                  </Grid>
                </Grid>
              }
            />
          ) : null}
        </ListItem>
      </List>
      <Menu
        id='header-user-menu'
        anchorEl={anchorEl}
        keepMounted
        open={isOpened}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </div>
  )
}

export default HeaderUser
