import React from 'react'
import { Provider } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history, store } from 'store'
import { pick } from 'lodash'
import {
  API_BASE_URL,
  API_KEY,
  GOOGLE_ANALYTICS,
  PUSHER_CONFIG
} from 'config/env'
import { Provider as EchoProvider } from '@doinn/shared/src/hoc/echo'
import withTracker from '@doinn/shared/src/hoc/tracker/withTracker'
import App from 'containers/app/App'
import Chats from 'scenes/Chats'
import Login from 'scenes/Login'
import NotFound from 'scenes/NotFound'

const privateRoute = Component => isUserAuthed => {
  const RedirectLogin = props => (
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )

  return isUserAuthed ? Component : RedirectLogin
}

const allRoutes = {
  Login: {
    key: 'Login',
    name: 'Login',
    path: '/login',
    component: () => Login,
    navbar: true,
    exact: true
  },

  Dashboard: {
    key: 'Dashboard',
    name: 'Dashboard',
    path: '/',
    component: privateRoute(Chats),
    navbar: true,
    exact: true
  },

  Messages: {
    key: 'Messages',
    name: 'Messages',
    path: '/messages',
    component: privateRoute(Chats),
    navbar: true,
    exact: true
  },

  NotFound: {
    key: 'NotFound',
    name: 'Page not found',
    path: '*',
    component: privateRoute(NotFound)
  }
}

const appRoutes = pick(allRoutes, [
  'Login',
  'Dashboard',
  'Messages',
  'NotFound'
])
const siteRoutes = pick(allRoutes, [
  'Login',
  'Dashboard',
  'Messages',
  'NotFound'
])

const Routes = () => (
  <Provider store={store}>
    <EchoProvider
      apiKey={API_KEY}
      apiBaseUrl={API_BASE_URL}
      config={PUSHER_CONFIG}
    >
      <ConnectedRouter history={history}>
        <Route component={withTracker(App, GOOGLE_ANALYTICS, {})} />
      </ConnectedRouter>
    </EchoProvider>
  </Provider>
)

export { allRoutes, appRoutes, siteRoutes }
export default Routes
