import {
  parse as parseQueryString,
  stringify as stringifyQueryString
} from 'query-string'
import { cloneDeep } from 'lodash'

export const getQueryString = (params = {}) => {
  return cloneDeep(
    stringifyQueryString(cloneDeep(params), {
      arrayFormat: 'bracket'
    })
  )
}

export const getQueryStringObject = (params = {}) => {
  return cloneDeep(
    parseQueryString(cloneDeep(params), {
      arrayFormat: 'bracket'
    })
  )
}

export const updateURLParams = (params = {}, history) => {
  if (!history || !history.push) return
  history.push({
    search: getQueryString(params)
  })
}

export const updateURL = (pathname = '', params = {}, history) => {
  if (!history || !history.push) return
  history.push({
    pathname,
    search: getQueryString(params)
  })
}

export const getNestedQueryString = (params = {}) => {
  const nestedParams = {}

  Object.entries(params).forEach(([key, value]) => {
    nestedParams[key] = cloneDeep(
      stringifyQueryString(cloneDeep(value), {
        arrayFormat: 'bracket'
      })
    )
  })

  return cloneDeep(
    stringifyQueryString(cloneDeep(nestedParams), {
      arrayFormat: 'bracket'
    })
  )
}

export const getNestedQueryStringObject = (params = {}) => {
  const nestedParams = cloneDeep(
    parseQueryString(cloneDeep(params), {
      arrayFormat: 'bracket'
    })
  )

  Object.entries(nestedParams).forEach(([key, value]) => {
    nestedParams[key] = cloneDeep(
      parseQueryString(cloneDeep(value), {
        arrayFormat: 'bracket'
      })
    )
  })

  return nestedParams
}

export const updateURLNestedParams = (params = {}, history) => {
  if (!history || !history.push) return
  history.push({
    search: getNestedQueryString(params)
  })
}
