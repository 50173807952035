import {
  NOTIFICATIONS_COUNTER_SETTED,
  NOTIFICATIONS_FETCH_MORE_REQUESTED,
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATION_CHECK_AS_READ_REQUESTED
} from '@doinn/shared/src/containers/notifications/constants'

export function fetchNotifications(params = {}) {
  return { type: NOTIFICATIONS_FETCH_REQUESTED, payload: params }
}

export function fetchMoreNotifications(params = {}) {
  return {
    type: NOTIFICATIONS_FETCH_MORE_REQUESTED,
    payload: params
  }
}

export function checkNotificationAsRead(id) {
  return { type: NOTIFICATION_CHECK_AS_READ_REQUESTED, payload: { id } }
}

export function setNotificationsCounter(total) {
  return { type: NOTIFICATIONS_COUNTER_SETTED, payload: { data: total } }
}
