import {
  CHAT_ADD_FORM_CLOSE_REQUESTED,
  CHAT_ADD_FORM_OPEN_REQUESTED,
  CHAT_ADD_FORM_SAVE_FAILED,
  CHAT_ADD_FORM_SAVE_REQUESTED,
  CHAT_ADD_FORM_SAVE_SUCCEEDED,
  CHAT_ADD_FORM_SET_EXISTING_CHAT
} from './constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastSavedItem: null,
  existingChat: null,
  isOpened: false,
  status: 'idle',
  searchText: ''
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CHAT_ADD_FORM_OPEN_REQUESTED:
      return {
        ...initialState,
        isOpened: true
      }
    case CHAT_ADD_FORM_CLOSE_REQUESTED:
      return {
        ...initialState,
        isOpened: false
      }
    case CHAT_ADD_FORM_SAVE_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.SAVING
      }
    case CHAT_ADD_FORM_SAVE_SUCCEEDED:
      return {
        ...initialState,
        lastSavedItem: { ...payload.chat },
        isOpened: false,
        status: STATUS.SUCCESS
      }
    case CHAT_ADD_FORM_SAVE_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.FAILURE
      }
    case CHAT_ADD_FORM_SET_EXISTING_CHAT:
      return {
        ...state,
        existingChat: payload.chat ? { ...payload.chat } : null
      }
    default:
      return state
  }
}
