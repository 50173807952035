const serviceWorkerConfig = {
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload()
          window.localStorage.setItem('showAppUpdateInfo', true)
        }
      })
    }
  }
}

export default serviceWorkerConfig
