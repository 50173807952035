const shadows = [
  'none',
  '0px 0px 2px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 6px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 12px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 14px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 16px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 18px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 22px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 24px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 26px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 28px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 30px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 32px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 34px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 36px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 38px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 40px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 42px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 44px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 46px 0px rgba(0, 0, 0, 0.1)',
  '0px 0px 48px 0px rgba(0, 0, 0, 0.1)'
]

export default shadows
