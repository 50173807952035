import { isInStandaloneMode } from '@doinn/shared/src/hooks/useAppDetector'

const isAdminScope = () => window?.location?.hostname?.startsWith('admin')

const isChatPage = () => window?.location?.pathname?.startsWith('/messages')

export const isHideDefaultLauncher = () =>
  isInStandaloneMode() || isAdminScope() || isChatPage()

export const showOrHideIntercom = () => {
  const intercom = window?.Intercom
  if (!intercom) return

  intercom('update', {
    hide_default_launcher: isHideDefaultLauncher()
  })
}
