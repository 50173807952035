import React from 'react'
import { makeStyles } from '@material-ui/core'
import DoinnLogo from '@doinn/shared/src/components/app/DoinnLogo'

const useStyles = makeStyles(theme => ({
  content: {
    display: 'inline-flex',
    flex: '0 0 auto'
  }
}))

const MainMenuLogo = props => {
  const classes = useStyles()

  return (
    <a className={classes.content} href='/'>
      <DoinnLogo />
    </a>
  )
}

export default MainMenuLogo
