import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, DialogContent, IconButton, makeStyles } from '@material-ui/core'
import Icon from '@doinn/shared/src/components/common/Icon'
import NotificationsList from '@doinn/shared/src/containers/notifications/List'
import Dialog from '@doinn/shared/src/components/common/dialog/Dialog'
import DialogHeader from '@doinn/shared/src/components/common/dialog/DialogHeader'

const useStyles = makeStyles(theme => {
  return {
    badge: {
      '& > span': {
        transform: 'scale(0.8) translate(50%, -50%)'
      }
    },
    body: {
      padding: theme.spacing(0, 2)
    }
  }
})

const NotificationCounter = ({ counter, onClick }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isOpened, setIsOpened] = React.useState(false)

  const handleClick = () => {
    onClick(0)
    setIsOpened(true)
  }

  const handleClose = () => {
    setIsOpened(false)
  }

  const handleClickItem = _ => handleClose()

  return (
    <div>
      <IconButton
        color='inherit'
        aria-label='open notifications'
        edge='end'
        onClick={handleClick}
      >
        <Badge
          badgeContent={counter > 99 ? '99+' : counter}
          color='primary'
          overlap='circle'
          className={classes.badge}
        >
          <Icon icon='notification' />
        </Badge>
      </IconButton>
      <Dialog open={isOpened} onClose={handleClose}>
        <DialogHeader title={t('Notifications')} onClose={handleClose} />
        <DialogContent dividers className={classes.body}>
          <NotificationsList onClickItem={handleClickItem} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default NotificationCounter
