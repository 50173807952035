import { cloneDeep, forIn, has } from 'lodash'

/**
 * @param {Object} currentFilters
 * @param {Object} newFilters
 * @returns {Object} appliedFilters
 */
export const parseAppliedFilters = (currentFilters = {}, newFilters = {}) => {
  const appliedFilters = cloneDeep(currentFilters)

  forIn(newFilters, (newFilterValue, key) => {
    if (newFilterValue && has(appliedFilters, key) && appliedFilters[key]) {
      if (
        Array.isArray(newFilterValue) &&
        !appliedFilters[key].includes(...newFilterValue)
      ) {
        appliedFilters[key].push(...newFilterValue)
      } else {
        appliedFilters[key] = newFilterValue
      }
    }
  })

  return cloneDeep(appliedFilters)
}

/**
 * @param {Object} currentFilters
 * @param {Object} newFilters
 * @returns {Object} appliedFilters
 */
export const parseAppliedDynamicFilters = (
  currentFilters = {},
  newFilters = {}
) => {
  const appliedFilters = cloneDeep(currentFilters)

  forIn(newFilters, (newFilterValue, key) => {
    if (newFilterValue) {
      if (has(appliedFilters, key) && appliedFilters[key]) {
        if (
          Array.isArray(newFilterValue) &&
          !appliedFilters[key].includes(...newFilterValue)
        ) {
          appliedFilters[key].push(...newFilterValue)
        } else {
          appliedFilters[key] = newFilterValue
        }
      } else {
        appliedFilters[key] = newFilterValue
      }
    }
  })

  return cloneDeep(appliedFilters)
}
