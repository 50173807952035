// @flow
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  spacer: {
    flexGrow: 1,
    alignSelf: 'stretch',
    justifySelf: 'stretch'
  }
}))

export type Props = {}

const FlexSpacer = (props: Props) => {
  const classes = useStyles()
  return <div className={classes.spacer} />
}

export default FlexSpacer
