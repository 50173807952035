export default theme => ({
  text: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    margin: '0 auto',
    width: '80%'
  },
  form: {
    maxWidth: 500,
    width: '100%'
  },
  error: {
    color: theme.palette.error.main
  }
})
