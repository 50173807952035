import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getChatApi } from '@doinn/shared/src/api/chats'
import {
  CHAT_GET_FAILED,
  CHAT_GET_REQUESTED,
  CHAT_GET_SUCCEEDED
} from '@doinn/shared/src/containers/chats/get/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* getChat(action) {
  try {
    const response = yield call(getChatApi, action.payload.params)
    yield put({
      type: CHAT_GET_SUCCEEDED,
      payload: response.data
    })
  } catch (e) {
    yield put({
      type: CHAT_GET_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* chatGetSaga() {
  yield all([takeLatest(CHAT_GET_REQUESTED, getChat)])
}
