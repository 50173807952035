import React from 'react'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import doinnMaterialUITheme from '@doinn/shared/src/config/theme'

const TemplateDefault = props => {
  const { children } = props

  return (
    <ThemeProvider theme={doinnMaterialUITheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default TemplateDefault
