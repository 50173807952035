import axios from 'axios'
import { ELASTICSEARCH_BASE_URL } from 'config/env'
import {
  camelizeResponseInterceptor,
  decamelizeRequestInterceptor,
  elasticsearchResponseInterceptor,
  promiseRejectInterceptorError
} from '@doinn/shared/src/config/axiosInterceptors'

const DoinnElasticsearch = axios.create({
  baseURL: ELASTICSEARCH_BASE_URL,
  timeout: parseInt('50000', 10)
})

// Converts all responses for CamelCase
DoinnElasticsearch.interceptors.response.use(
  camelizeResponseInterceptor,
  promiseRejectInterceptorError
)

DoinnElasticsearch.interceptors.response.use(
  elasticsearchResponseInterceptor,
  promiseRejectInterceptorError
)

DoinnElasticsearch.interceptors.request.use(
  decamelizeRequestInterceptor,
  promiseRejectInterceptorError
)

export default DoinnElasticsearch
