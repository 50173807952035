export const CHATS_FETCH_REQUESTED = 'CHATS_FETCH_REQUESTED'
export const CHATS_FETCH_SUCCEEDED = 'CHATS_FETCH_SUCCEEDED'
export const CHATS_FETCH_FAILED = 'CHATS_FETCH_FAILED'

export const CHATS_FETCH_MORE_REQUESTED = 'CHATS_FETCH_MORE_REQUESTED'
export const CHATS_FETCH_MORE_SUCCEEDED = 'CHATS_FETCH_MORE_SUCCEEDED'
export const CHATS_FETCH_MORE_FAILED = 'CHATS_FETCH_MORE_FAILED'

export const CHAT_DELETE_REQUESTED = 'CHAT_DELETE_REQUESTED'
export const CHAT_DELETE_SUCCEEDED = 'CHAT_DELETE_SUCCEEDED'
export const CHAT_DELETE_FAILED = 'CHAT_DELETE_FAILED'

export const CHAT_MARK_AS_READ_REQUESTED = 'CHAT_MARK_AS_READ_REQUESTED'
export const CHAT_MARK_AS_READ_SUCCEEDED = 'CHAT_MARK_AS_READ_SUCCEEDED'
export const CHAT_MARK_AS_READ_FAILED = 'CHAT_MARK_AS_READ_FAILED'

export const CHAT_PARTICIPANT_ADD_REQUESTED = 'CHAT_PARTICIPANT_ADD_REQUESTED'
export const CHAT_PARTICIPANT_ADD_SUCCEEDED = 'CHAT_PARTICIPANT_ADD_SUCCEEDED'
export const CHAT_PARTICIPANT_ADD_FAILED = 'CHAT_PARTICIPANT_ADD_FAILED'

export const CHAT_PARTICIPANT_REMOVE_REQUESTED =
  'CHAT_PARTICIPANT_REMOVE_REQUESTED'
export const CHAT_PARTICIPANT_REMOVE_SUCCEEDED =
  'CHAT_PARTICIPANT_REMOVE_SUCCEEDED'
export const CHAT_PARTICIPANT_REMOVE_FAILED = 'CHAT_PARTICIPANT_REMOVE_FAILED'

export const CHAT_DEFAULT_FIRST_PAGE = 1
export const CHAT_DEFAULT_ROWS_PER_PAGE = 30

export const CHAT_ADD_SUCCEEDED = 'CHAT_ADD_SUCCEEDED'
export const CHAT_UPDATE_SUCCEEDED = 'CHAT_UPDATE_SUCCEEDED'

export const CHAT_UPDATE_UNREAD_MESSAGES_COUNTER =
  'CHAT_UPDATE_UNREAD_MESSAGES_COUNTER'
