import i18n from '@doinn/shared/src/config/i18n'

export const getSnackbarErrorMessage = error => {
  if (typeof error === 'object') {
    // Connection aborted (ex.: Timeout)
    if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
      return i18n.t(
        "It wasn't possible to carry out this action. Please try again."
      )
    }

    // API error
    try {
      const message = error.response.data.data.errors.message
      if (message) return message
    } catch (e) {}

    // Catch error message
    try {
      const message = error.message
      if (message) return message
    } catch (e) {}
  }

  if (typeof error === 'string') return error

  // Unexpected error
  return 'An unexpected error has occurred. Try again later.'
}
