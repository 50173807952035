import React from 'react'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'

export default WrappedComponent => {
  const AppDetectorComponent = props => (
    <WrappedComponent isApp={useAppDetector()} {...props} />
  )

  return AppDetectorComponent
}
