import {
  AUTH_LOGIN_REQUESTED,
  AUTH_RECOVER_PASSWORD_REQUESTED
} from '@doinn/shared/src/containers/auth/constants'

export function login(email, password) {
  return {
    payload: { email, password },
    type: AUTH_LOGIN_REQUESTED
  }
}

export function recoverPassword(email) {
  return {
    payload: { email },
    type: AUTH_RECOVER_PASSWORD_REQUESTED
  }
}
