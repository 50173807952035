import React from 'react'
import { compose } from 'redux'
import { Box, Grid } from '@material-ui/core'
import LoadingText from '@doinn/shared/src/components/common/loading/LoadingText'

const NotificationsListItemLoading = () => {
  return (
    <Box py={2}>
      <Grid container justify='space-between' wrap='nowrap' spacing={2}>
        <Grid item xs>
          <LoadingText textVariant='body2' width='60%' />
        </Grid>
        <Grid item>
          <LoadingText textVariant='body2' width={32} />
        </Grid>
      </Grid>
    </Box>
  )
}

NotificationsListItemLoading.propTypes = {}

export default compose()(NotificationsListItemLoading)
