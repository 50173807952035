// @flow
/**
 * Based on https://github.com/alibaba/hooks/blob/master/packages/hooks/src/useMount/index.ts
 */
import { useEffect } from 'react'
import usePersistFn from '@doinn/shared/src/hooks/usePersistFn'

const useDidMount = (fn: any) => {
  // Persist function
  const fnPersist = usePersistFn(fn)

  useEffect(() => {
    if (fnPersist && typeof fnPersist === 'function') {
      fnPersist()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useDidMount
