import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  addChatParticipantsApi,
  deleteChatApi,
  fetchChatsApi,
  markChatAsReadApi,
  removeChatParticipantApi
} from '@doinn/shared/src/api/chats'
import {
  CHATS_FETCH_FAILED,
  CHATS_FETCH_MORE_FAILED,
  CHATS_FETCH_MORE_REQUESTED,
  CHATS_FETCH_MORE_SUCCEEDED,
  CHATS_FETCH_REQUESTED,
  CHATS_FETCH_SUCCEEDED,
  CHAT_DEFAULT_FIRST_PAGE,
  CHAT_DEFAULT_ROWS_PER_PAGE,
  CHAT_DELETE_FAILED,
  CHAT_DELETE_REQUESTED,
  CHAT_DELETE_SUCCEEDED,
  CHAT_MARK_AS_READ_FAILED,
  CHAT_MARK_AS_READ_REQUESTED,
  CHAT_MARK_AS_READ_SUCCEEDED,
  CHAT_PARTICIPANT_ADD_FAILED,
  CHAT_PARTICIPANT_ADD_REQUESTED,
  CHAT_PARTICIPANT_ADD_SUCCEEDED,
  CHAT_PARTICIPANT_REMOVE_FAILED,
  CHAT_PARTICIPANT_REMOVE_REQUESTED,
  CHAT_PARTICIPANT_REMOVE_SUCCEEDED
} from '@doinn/shared/src/containers/chats/constants'
import { fetchChats as fetchChatsAction } from '@doinn/shared/src/containers/chats/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* deleteChat(action) {
  try {
    const response = yield call(deleteChatApi, action.payload.chat)
    const { data } = response.data
    yield put({
      type: CHAT_DELETE_SUCCEEDED,
      payload: { data }
    })
    yield put(
      fetchChatsAction({
        page: CHAT_DEFAULT_FIRST_PAGE,
        limit: CHAT_DEFAULT_ROWS_PER_PAGE
      })
    )
  } catch (e) {
    yield put({
      type: CHAT_DELETE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchChats(action) {
  try {
    const response = yield call(fetchChatsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHATS_FETCH_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHATS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreChats(action) {
  try {
    const response = yield call(fetchChatsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHATS_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHATS_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* markChatAsRead(action) {
  try {
    const response = yield call(markChatAsReadApi, action.payload.id)
    const chatsResponse = response.data
    yield put({
      type: CHAT_MARK_AS_READ_SUCCEEDED,
      payload: chatsResponse
    })
  } catch (e) {
    yield put({
      type: CHAT_MARK_AS_READ_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* addChatParticipants(action) {
  try {
    const { chat, participants } = action.payload
    const response = yield call(addChatParticipantsApi, chat, participants)
    const { data } = response.data
    yield put({
      type: CHAT_PARTICIPANT_ADD_SUCCEEDED,
      payload: { chat: data }
    })
  } catch (e) {
    yield put({
      type: CHAT_PARTICIPANT_ADD_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* removeChatParticipant(action) {
  try {
    const { chat, participant } = action.payload
    const response = yield call(removeChatParticipantApi, chat, participant)
    const { data } = response.data
    yield put({
      type: CHAT_PARTICIPANT_REMOVE_SUCCEEDED,
      payload: { chat: data }
    })
  } catch (e) {
    yield put({
      type: CHAT_PARTICIPANT_REMOVE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* chatsSaga() {
  yield all([
    takeLatest(CHATS_FETCH_REQUESTED, fetchChats),
    takeLatest(CHATS_FETCH_MORE_REQUESTED, fetchMoreChats),
    takeLatest(CHAT_DELETE_REQUESTED, deleteChat),
    takeEvery(CHAT_MARK_AS_READ_REQUESTED, markChatAsRead),
    takeLatest(CHAT_PARTICIPANT_ADD_REQUESTED, addChatParticipants),
    takeLatest(CHAT_PARTICIPANT_REMOVE_REQUESTED, removeChatParticipant)
  ])
}
