import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enTranslationShared from '@doinn/shared/src/translations/en_GB.json'
import esTranslationShared from '@doinn/shared/src/translations/es_ES.json'
import frTranslationShared from '@doinn/shared/src/translations/fr_FR.json'
import ptTranslationShared from '@doinn/shared/src/translations/pt_PT.json'
import enTranslation from 'translations/en_GB.json'
import esTranslation from 'translations/es_ES.json'
import frTranslation from 'translations/fr_FR.json'
import ptTranslation from 'translations/pt_PT.json'
import { merge } from 'lodash'

const IS_DEVELOPMENT = process?.env?.NODE_ENV === 'development'

const resources = {
  en: { translation: merge(enTranslationShared, enTranslation) },
  es: { translation: merge(esTranslationShared, esTranslation) },
  fr: { translation: merge(frTranslationShared, frTranslation) },
  pt: { translation: merge(ptTranslationShared, ptTranslation) }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false
  },
  // use `debug: true` to get more info about missing keys!
  // debug: IS_DEVELOPMENT,
  saveMissing: IS_DEVELOPMENT,
  missingKeyHandler: IS_DEVELOPMENT
    ? (lng, ns, key, fallbackValue) => {
        // eslint-disable-next-line
        // console.log('Missing key in translations', {
        //   lng,
        //   ns,
        //   key,
        //   fallbackValue
        // })
        // eslint-disable-next-line
        // console.log(`Missing key in translations "${key}"`)
        if (window.doinnI18nMissingKeys === undefined) {
          window.doinnI18nMissingKeys = []
          console.log(
            "Check the missing keys in translations on 'window.doinnI18nMissingKeys'"
          )
        }
        if (!window.doinnI18nMissingKeys.includes(key)) {
          window.doinnI18nMissingKeys.push(key)
        }
      }
    : false
})

export default i18n
