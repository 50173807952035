import DoinnElasticsearch from '@doinn/shared/src/config/doinnElasticsearch'
import bodybuilder from 'bodybuilder'

export function fetchPropertiesElasticsearch(filters = {}) {
  const config = {}

  const body = bodybuilder()

  if (filters.page && filters.limit) {
    body.from((filters.page - 1) * filters.limit)
  } else {
    body.from(0)
  }
  if (filters.limit) {
    body.size(filters.limit)
  }
  if (filters.fields) {
    body.rawOption('_source', { includes: [...filters.fields] })
  }

  body.query('bool', {}, q => {
    q.query('bool', {}, q => {
      if (filters.id) {
        q.orQuery('match', 'id', filters.id)
      }
      if (filters.ids && Array.isArray(filters.ids)) {
        filters.ids.forEach(id => {
          q.orQuery('match', 'id', id)
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.vendorBusinessAccountId) {
        q.orQuery(
          'match',
          'vendors_business_account',
          filters.vendorBusinessAccountId
        )
      }
      if (
        filters.vendorBusinessAccountIds &&
        Array.isArray(filters.vendorBusinessAccountIds)
      ) {
        filters.vendorBusinessAccountIds.forEach(vendorBusinessAccountId => {
          q.orQuery(
            'match',
            'vendors_business_account',
            vendorBusinessAccountId
          )
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.vendorId) {
        q.orQuery('match', 'vendors', filters.vendorId)
      }
      if (filters.vendorIds && Array.isArray(filters.vendorIds)) {
        filters.vendorIds.forEach(vendorId => {
          q.orQuery('match', 'vendors', vendorId)
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.hostBusinessAccountId) {
        q.orQuery(
          'match',
          'host_business_account_id',
          filters.hostBusinessAccountId
        )
      }
      if (
        filters.hostBusinessAccountIds &&
        Array.isArray(filters.hostBusinessAccountIds)
      ) {
        filters.hostBusinessAccountIds.forEach(hostBusinessAccountId => {
          q.orQuery('match', 'host_business_account_id', hostBusinessAccountId)
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.hostId) {
        q.orQuery('match', 'hostId', filters.hostId)
      }
      if (filters.hostIds && Array.isArray(filters.hostIds)) {
        filters.hostIds.forEach(hostId => {
          q.orQuery('match', 'hostId', hostId)
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.employeeId) {
        q.orQuery('match', 'employees', filters.employeeId)
      }
      if (filters.employeeIds && Array.isArray(filters.employeeIds)) {
        filters.employeeIds.forEach(employeeId => {
          q.orQuery('match', 'employees', employeeId)
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.cityId) {
        q.orQuery('match', 'cityId', filters.cityId)
      }
      if (filters.cityIds && Array.isArray(filters.cityIds)) {
        filters.cityIds.forEach(cityId => {
          q.orQuery('match', 'cityId', cityId)
        })
      }
      return q
    })
    q.query('bool', {}, q => {
      if (filters.spaceCategoryId) {
        q.orQuery('match', 'spaceCategoryId', filters.spaceCategoryId)
      }
      if (filters.spaceCategoryIds && Array.isArray(filters.spaceCategoryIds)) {
        filters.spaceCategoryIds.forEach(spaceCategoryId => {
          q.orQuery('match', 'spaceCategoryId', spaceCategoryId)
        })
      }
      return q
    })
    if (filters.search) {
      q.query('multi_match', {
        query: filters.search,
        fields: ['name', 'code', 'address']
      })
    }
    return q
  })

  const data = body.build()

  return DoinnElasticsearch.post(`/space/_search`, data, config)
}
