import * as PusherPushNotifications from '@pusher/push-notifications-web'
import { get } from 'lodash'
import { API_BASE_URL, API_KEY, PUSHER_CONFIG } from 'config/env'
import { getCurrentAuthTokenApi } from '@doinn/shared/src/api/auth'
import { getLoggedUserApi } from '@doinn/shared/src/api/user'

export const pushNotificationSetup = async serviceWorkerRegistration => {
  const authToken = getCurrentAuthTokenApi()
  const defaultInterests = ['general']
  const beamsClient = new PusherPushNotifications.Client({
    instanceId: PUSHER_CONFIG.beams.instanceId,
    serviceWorkerRegistration
  })

  const getCurrentUser = async () => {
    if (!authToken) {
      return
    }

    const data = await getLoggedUserApi()
    return data.data.data
  }

  const checkUser = async currentUserId => {
    const userId = await beamsClient.getUserId()

    if (userId !== currentUserId) {
      await beamsClient.clearAllState()
    }
  }

  try {
    await beamsClient.start()

    const currentUser = await getCurrentUser()

    if (currentUser) {
      const currentUserId = `${currentUser.id}`
      const useInterests = get(currentUser, 'notification.interests', [])
      const interests = defaultInterests.concat(useInterests)

      await checkUser(currentUserId)

      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: `${API_BASE_URL}${PUSHER_CONFIG.beams.tokenProviderPath}`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          'api-key': `${API_KEY}`
        }
      })

      beamsClient.setUserId(currentUserId, beamsTokenProvider)
      beamsClient.setDeviceInterests(interests)
    } else {
      await beamsClient.clearAllState()
      await beamsClient.setDeviceInterests(defaultInterests)
    }
  } catch (e) {
    console.error(e)
  }
}
