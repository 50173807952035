import { all, call, put, takeLatest } from 'redux-saga/effects'
import { followChatApi, unfollowChatApi } from '@doinn/shared/src/api/chats'
import {
  CHAT_FOLLOW_FAILED,
  CHAT_FOLLOW_REQUESTED,
  CHAT_FOLLOW_SUCCEEDED,
  CHAT_UNFOLLOW_FAILED,
  CHAT_UNFOLLOW_REQUESTED,
  CHAT_UNFOLLOW_SUCCEEDED
} from '@doinn/shared/src/containers/chats/follow/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* followChat(action) {
  try {
    const response = yield call(followChatApi, action.payload.chat)
    const { data } = response.data
    yield put({
      type: CHAT_FOLLOW_SUCCEEDED,
      payload: { chat: data }
    })
  } catch (e) {
    yield put({
      type: CHAT_FOLLOW_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* unfollowChat(action) {
  try {
    const response = yield call(unfollowChatApi, action.payload.chat)
    const { data } = response.data
    yield put({
      type: CHAT_UNFOLLOW_SUCCEEDED,
      payload: { chat: data }
    })
  } catch (e) {
    yield put({
      type: CHAT_UNFOLLOW_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* chatsSaga() {
  yield all([
    takeLatest(CHAT_FOLLOW_REQUESTED, followChat),
    takeLatest(CHAT_UNFOLLOW_REQUESTED, unfollowChat)
  ])
}
