import { cloneDeep } from 'lodash'
import {
  USER_GET_FAILED,
  USER_GET_REQUESTED,
  USER_GET_SUCCEEDED,
  USER_LOGOUT_FAILED,
  USER_LOGOUT_REQUESTED,
  USER_LOGOUT_SUCCEEDED
} from '@doinn/shared/src/containers/user/constants'
import { AUTOMATION_ADD_SUCCEEDED } from '@doinn/host/src/containers/automations/add/constants'
import { AUTOMATIONS_DELETE_SUCCEEDED } from '@doinn/host/src/containers/automations/list/delete/constants'
import { INTEGRATION_ADD_SUCCEEDED } from '@doinn/host/src/containers/integrations/add/constants'
import { INTEGRATIONS_DELETE_SUCCEEDED } from '@doinn/host/src/containers/integrations/list/delete/constants'

export const initialState = {
  loggedUser: null,
  userHasLoggedOut: false,
  isLoadingLoggedUser: false,
  isFailedLoggedUser: false,
  isLoadingLogout: false,
  isFailedLogout: false
}

const parseLoggedUserBusinessAreaCounterToState = (
  state,
  area,
  modifier = 0
) => {
  const newState = cloneDeep(state)
  if (!['integrations', 'automations'].includes(area)) return newState
  try {
    newState.loggedUser.business[area].total =
      newState.loggedUser.business[area].total + modifier
  } catch (e) {}
  return newState
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_GET_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoadingLoggedUser: true,
        isFailedLoggedUser: false
      }
    case USER_GET_SUCCEEDED:
      return {
        ...state,
        loggedUser: { ...payload.data },
        isLoadingLoggedUser: false,
        isFailedLoggedUser: false
      }
    case USER_GET_FAILED:
      return {
        ...state,
        isLoadingLoggedUser: false,
        isFailedLoggedUser: true
      }
    case USER_LOGOUT_REQUESTED:
      return {
        ...state,
        userHasLoggedOut: false,
        isLoadingLogout: true,
        isFailedLogout: false
      }
    case USER_LOGOUT_SUCCEEDED:
      return {
        ...initialState,
        userHasLoggedOut: true,
        isLoadingLogout: false,
        isFailedLogout: false
      }
    case USER_LOGOUT_FAILED:
      return {
        ...state,
        userHasLoggedOut: false,
        isLoadingLogout: false,
        isFailedLogout: true
      }
    case AUTOMATION_ADD_SUCCEEDED:
      return parseLoggedUserBusinessAreaCounterToState(state, 'automations', 1)
    case AUTOMATIONS_DELETE_SUCCEEDED:
      return parseLoggedUserBusinessAreaCounterToState(state, 'automations', -1)
    case INTEGRATION_ADD_SUCCEEDED:
      return parseLoggedUserBusinessAreaCounterToState(state, 'integrations', 1)
    case INTEGRATIONS_DELETE_SUCCEEDED:
      return parseLoggedUserBusinessAreaCounterToState(
        state,
        'integrations',
        -1
      )
    default:
      return state
  }
}
