import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchChatsContactsApi } from '@doinn/shared/src/api/chats'
import {
  CHATS_CONTACTS_FETCH_FAILED,
  CHATS_CONTACTS_FETCH_REQUESTED,
  CHATS_CONTACTS_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/chats/contacts/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchChatsContacts(action) {
  try {
    const response = yield call(fetchChatsContactsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHATS_CONTACTS_FETCH_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHATS_CONTACTS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* chatsSaga() {
  yield all([takeLatest(CHATS_CONTACTS_FETCH_REQUESTED, fetchChatsContacts)])
}
