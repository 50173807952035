import {
  CHAT_FOLLOW_FAILED,
  CHAT_FOLLOW_REQUESTED,
  CHAT_FOLLOW_SUCCEEDED,
  CHAT_UNFOLLOW_FAILED,
  CHAT_UNFOLLOW_REQUESTED,
  CHAT_UNFOLLOW_SUCCEEDED
} from '@doinn/shared/src/containers/chats/follow/constants'

export const STATUS = {
  IDLE: 'idle',
  SAVING: 'saving',
  SUCCESS: 'success',
  FAILURE: 'failure'
}

export const initialState = {
  lastSavedItem: null,
  isOpened: false,
  status: STATUS.IDLE
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CHAT_FOLLOW_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.SAVING
      }
    case CHAT_FOLLOW_SUCCEEDED:
      return {
        ...state,
        lastSavedItem: { ...payload.chat },
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case CHAT_FOLLOW_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.FAILURE
      }
    case CHAT_UNFOLLOW_REQUESTED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.SAVING
      }
    case CHAT_UNFOLLOW_SUCCEEDED:
      return {
        ...state,
        lastSavedItem: { ...payload.chat },
        status: STATUS.SUCCESS,
        isOpened: false
      }
    case CHAT_UNFOLLOW_FAILED:
      return {
        ...state,
        lastSavedItem: null,
        status: STATUS.FAILURE
      }
    default:
      return state
  }
}
