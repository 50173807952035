import { all, call, put, takeLatest } from 'redux-saga/effects'
import { get } from 'lodash'
import i18n from '@doinn/shared/src/config/i18n'
import { authLoginApi, recoverPasswordApi } from '@doinn/shared/src/api/auth'
import { getLoggedUser } from '@doinn/shared/src/containers/user/actions'
import {
  AUTH_LOGIN_FAILED,
  AUTH_LOGIN_REQUESTED,
  AUTH_LOGIN_SUCCEEDED,
  AUTH_RECOVER_PASSWORD_FAILED,
  AUTH_RECOVER_PASSWORD_REQUESTED,
  AUTH_RECOVER_PASSWORD_SUCCEEDED
} from '@doinn/shared/src/containers/auth/constants'

function* login(action) {
  try {
    const { email, password } = action.payload
    const response = yield call(authLoginApi, email, password)
    const { token } = response.data.data
    window.localStorage.setItem('authToken', token)
    if (token) {
      yield put({
        type: AUTH_LOGIN_SUCCEEDED,
        payload: token
      })
      yield put(getLoggedUser())
    }
  } catch (e) {
    yield put({
      type: AUTH_LOGIN_FAILED,
      payload: {
        error: i18n.t('These credentials do not match our records.')
      }
    })
  }
}

function* recoverPassword(action) {
  const errorMessage = i18n.t('This email is not registered.')

  try {
    const { email } = action.payload
    const response = yield call(recoverPasswordApi, email)
    const success = get(response, 'data.data.success')

    if (success) {
      yield put({
        type: AUTH_RECOVER_PASSWORD_SUCCEEDED,
        snackbar: {
          variant: 'success',
          message: i18n.t(
            'You will receive an email with instructions to reset your password!'
          )
        }
      })
    } else {
      yield put({
        type: AUTH_RECOVER_PASSWORD_FAILED,
        payload: { error: errorMessage }
      })
    }
  } catch (e) {
    yield put({
      type: AUTH_RECOVER_PASSWORD_FAILED,
      payload: { error: errorMessage }
    })
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(AUTH_LOGIN_REQUESTED, login),
    takeLatest(AUTH_RECOVER_PASSWORD_REQUESTED, recoverPassword)
  ])
}
