import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  checkNotificationAsReadApi,
  fetchNotificationsApi
} from '@doinn/shared/src/api/notifications'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'
import {
  NOTIFICATIONS_FETCH_FAILED,
  NOTIFICATIONS_FETCH_MORE_FAILED,
  NOTIFICATIONS_FETCH_MORE_REQUESTED,
  NOTIFICATIONS_FETCH_MORE_SUCCEEDED,
  NOTIFICATIONS_FETCH_REQUESTED,
  NOTIFICATIONS_FETCH_SUCCEEDED,
  NOTIFICATION_CHECK_AS_READ_FAILED,
  NOTIFICATION_CHECK_AS_READ_REQUESTED,
  NOTIFICATION_CHECK_AS_READ_SUCCEEDED
} from '@doinn/shared/src/containers/notifications/constants'

function* fetchNotifications(action) {
  try {
    const response = yield call(fetchNotificationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: NOTIFICATIONS_FETCH_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: NOTIFICATIONS_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreNotifications(action) {
  try {
    const response = yield call(fetchNotificationsApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: NOTIFICATIONS_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: NOTIFICATIONS_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* checkNotificationAsRead(action) {
  try {
    const response = yield call(checkNotificationAsReadApi, action.payload.id)
    const notificationsResponse = response.data
    yield put({
      type: NOTIFICATION_CHECK_AS_READ_SUCCEEDED,
      payload: notificationsResponse
    })
  } catch (e) {
    yield put({
      type: NOTIFICATION_CHECK_AS_READ_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* notificationsSagas() {
  yield all([
    takeLatest(NOTIFICATIONS_FETCH_REQUESTED, fetchNotifications),
    takeLatest(NOTIFICATIONS_FETCH_MORE_REQUESTED, fetchMoreNotifications),
    takeEvery(NOTIFICATION_CHECK_AS_READ_REQUESTED, checkNotificationAsRead)
  ])
}
