import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { Box, Container, makeStyles } from '@material-ui/core'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import MainContent from '@doinn/shared/src/components/app/MainContent'
import BottomMainMenu from 'containers/app/components/BottomMainMenu'
import TemplateBlank from '@doinn/shared/src/components/templates/TemplateBlank'
import Header from 'containers/app/Header'

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: 0
  },
  appBottomMainMenuSpacer: {
    height: `calc(env(safe-area-inset-bottom, 0) + ${theme.spacing(7)}px)`
  }
}))

const TemplateDefault = ({ children, filtersContent, header, title }) => {
  const classes = useStyles()
  const isApp = useAppDetector()

  return (
    <TemplateBlank>
      <Helmet defaultTitle='Doinn' titleTemplate='%s - Doinn'>
        <meta charSet='utf-8' />
      </Helmet>
      <Box display='flex'>
        <Container maxWidth='lg' className={classes.container}>
          <Header filtersContent={filtersContent} title={title}>
            {header}
          </Header>
          <MainContent>{children}</MainContent>
          {isApp && (
            <React.Fragment>
              <div
                id='appBottomMainMenuSpacer'
                className={classes.appBottomMainMenuSpacer}
              />
              <BottomMainMenu />
            </React.Fragment>
          )}
        </Container>
      </Box>
    </TemplateBlank>
  )
}

TemplateDefault.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  title: PropTypes.string
}

export default TemplateDefault
