import convertToCamelCaseKeys from '@doinn/shared/src/util/convertToCamelCaseKeys'
import {
  CHATS_FETCH_MORE_REQUESTED,
  CHATS_FETCH_REQUESTED,
  CHAT_ADD_SUCCEEDED,
  CHAT_DELETE_REQUESTED,
  CHAT_MARK_AS_READ_REQUESTED,
  CHAT_PARTICIPANT_ADD_REQUESTED,
  CHAT_PARTICIPANT_REMOVE_REQUESTED,
  CHAT_UPDATE_SUCCEEDED,
  CHAT_UPDATE_UNREAD_MESSAGES_COUNTER
} from '@doinn/shared/src/containers/chats/constants'

export function deleteChat(chat) {
  return {
    type: CHAT_DELETE_REQUESTED,
    payload: { chat }
  }
}

export function fetchChats(params = {}) {
  return { type: CHATS_FETCH_REQUESTED, payload: { params } }
}

export function fetchMoreChats(params = {}) {
  return {
    type: CHATS_FETCH_MORE_REQUESTED,
    payload: { params }
  }
}

export function markChatAsRead(id) {
  return { type: CHAT_MARK_AS_READ_REQUESTED, payload: { id } }
}

export function addParticipants(chat, participants) {
  return {
    type: CHAT_PARTICIPANT_ADD_REQUESTED,
    payload: {
      chat,
      participants
    }
  }
}

export function removeParticipant(chat, participant) {
  return {
    type: CHAT_PARTICIPANT_REMOVE_REQUESTED,
    payload: { chat, participant }
  }
}

export function addChatData(chat) {
  return {
    type: CHAT_ADD_SUCCEEDED,
    payload: { chat: convertToCamelCaseKeys(chat) }
  }
}

export function updateChatData(chat) {
  return {
    type: CHAT_UPDATE_SUCCEEDED,
    payload: { chat: convertToCamelCaseKeys(chat) }
  }
}

export function updateUnreadMessagesCounter(counter) {
  return {
    type: CHAT_UPDATE_UNREAD_MESSAGES_COUNTER,
    payload: { counter }
  }
}
