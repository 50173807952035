import { useHistory } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Capacitor } from '@capacitor/core'
import { useEcho } from '../hoc/echo/useEcho'

const useAppVersionUpdate = () => {
  const { echo } = useEcho()
  const history = useHistory()
  const { localStorage } = window
  const channel = 'deployments'
  const eventName = '.doinn-apps-deployed'
  const storageItemKey = 'doinnAppVersionInfo'
  const storedVersion = JSON.parse(localStorage.getItem(storageItemKey)) || null
  const [versionInfo, setVersionInfo] = useState(storedVersion)
  const isUpdated = !storedVersion || versionInfo.updated

  const updateAppVersion = useCallback(() => {
    if (!isUpdated) {
      localStorage.setItem(
        storageItemKey,
        JSON.stringify({
          ...versionInfo,
          updated: true,
          hasNotified: false
        })
      )
      window.location.reload()
    }
  }, [versionInfo, isUpdated, localStorage])

  const setVersionAsNotified = useCallback(() => {
    const versionInfoUpdated = {
      ...versionInfo,
      hasNotified: true
    }
    localStorage.setItem(storageItemKey, JSON.stringify(versionInfoUpdated))
  }, [localStorage, versionInfo])

  const echoListener = useCallback(() => {
    if (echo && !Capacitor.isNativePlatform()) {
      echo.channel(channel).listen(eventName, data => {
        setVersionInfo(data)
        localStorage.setItem(storageItemKey, JSON.stringify(data))
      })
    }
  }, [echo, localStorage, setVersionInfo])

  const removeEchoListener = useCallback(() => {
    if (echo) {
      echo.leaveChannel(channel)
    }
  }, [echo])

  const historyListener = useCallback(() => {
    history.listen(() => {
      if (!isUpdated) {
        updateAppVersion()
      }
    })
  }, [history, isUpdated, updateAppVersion])

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      echoListener()
      historyListener()
    }

    return () => {
      removeEchoListener()
    }
  }, [echoListener, historyListener, removeEchoListener])

  return { setVersionAsNotified, isUpdated, updateAppVersion, versionInfo }
}

export default useAppVersionUpdate
