import React from 'react'
import { EchoContext } from '@doinn/shared/src/hoc/echo/Provider'

export default Component => {
  const EchoComponent = props => (
    <EchoContext.Consumer>
      {state => {
        return (
          <Component
            {...props}
            context={state}
            echo={state.echo}
            echoUserChannel={state.echoUserChannel}
          />
        )
      }}
    </EchoContext.Consumer>
  )

  return EchoComponent
}
