// @flow
import type { Node } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DefaultImage from '@doinn/shared/src/components/common/empty-state/svgs/shining.svg'
import EmptyState from '@doinn/shared/src/components/common/empty-state/EmptyState'

export type Props = {
  image?: Node,
  title?: string,
  description?: string | Node
}

const EmptyList = (props: Props) => {
  const { t } = useTranslation()

  return (
    <EmptyState
      title={t('No results')}
      description={t('There are currently no results to show')}
      {...props}
    />
  )
}

EmptyList.defaultProps = {
  image: DefaultImage
}

export default EmptyList
