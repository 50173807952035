import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Box, Typography } from '@material-ui/core'
import TemplateDefault from 'components/TemplateDefault'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <TemplateDefault>
      <Helmet>
        <title>{t('Page not found')}</title>
      </Helmet>
      <Box pt={20}>
        <Typography align='center' variant='h4'>
          <b>{t('Page not found')}</b>
        </Typography>
      </Box>
    </TemplateDefault>
  )
}

export default NotFound
