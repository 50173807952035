export const NOTIFICATIONS_FETCH_REQUESTED = 'NOTIFICATIONS_FETCH_REQUESTED'
export const NOTIFICATIONS_FETCH_SUCCEEDED = 'NOTIFICATIONS_FETCH_SUCCEEDED'
export const NOTIFICATIONS_FETCH_FAILED = 'NOTIFICATIONS_FETCH_FAILED'

export const NOTIFICATIONS_FETCH_MORE_REQUESTED =
  'NOTIFICATIONS_FETCH_MORE_REQUESTED'
export const NOTIFICATIONS_FETCH_MORE_SUCCEEDED =
  'NOTIFICATIONS_FETCH_MORE_SUCCEEDED'
export const NOTIFICATIONS_FETCH_MORE_FAILED = 'NOTIFICATIONS_FETCH_MORE_FAILED'

export const NOTIFICATION_CHECK_AS_READ_REQUESTED =
  'NOTIFICATION_CHECK_AS_READ_REQUESTED'
export const NOTIFICATION_CHECK_AS_READ_SUCCEEDED =
  'NOTIFICATION_CHECK_AS_READ_SUCCEEDED'
export const NOTIFICATION_CHECK_AS_READ_FAILED =
  'NOTIFICATION_CHECK_AS_READ_FAILED'

export const NOTIFICATIONS_COUNTER_SETTED = 'NOTIFICATIONS_COUNTER_SETTED'
