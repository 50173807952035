import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { getQueryStringObject } from '@doinn/shared/src/util/url'
import { parseAppliedFilters } from '@doinn/shared/src/util/filters'
import loadable from '@loadable/component'
import TemplateDefault from 'components/TemplateDefault'

const defaultFilters = {
  page: 1,
  following: 'all'
}

const MessageListContainer = loadable(() =>
  import('@doinn/shared/src/containers/chats/List')
)

const Chats = ({ location }) => {
  const { t } = useTranslation()

  const queryFilters = getQueryStringObject(location.search)

  const filters = parseAppliedFilters(defaultFilters, queryFilters)

  return (
    <TemplateDefault title={t('Messages')}>
      <Helmet>
        <title>{t('Messages')}</title>
      </Helmet>
      <React.Suspense fallback={<div />}>
        <MessageListContainer filters={filters} />
      </React.Suspense>
    </TemplateDefault>
  )
}

export default Chats
