import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addChatApi } from '@doinn/shared/src/api/chats'
import {
  CHAT_ADD_FORM_SAVE_FAILED,
  CHAT_ADD_FORM_SAVE_REQUESTED,
  CHAT_ADD_FORM_SAVE_SUCCEEDED
} from '@doinn/shared/src/containers/chats/add/constants'
import { setExistingChat } from '@doinn/shared/src/containers/chats/add/actions'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

export function* addChat(action) {
  try {
    const { type, name, participants } = action.payload.params
    const params = {
      type,
      name,
      participants
    }
    const response = yield call(addChatApi, params)
    const chat = response.data.data
    yield put({
      type: CHAT_ADD_FORM_SAVE_SUCCEEDED,
      payload: { chat }
    })
  } catch (e) {
    if (e.response && e.response.status === 409) {
      const response = e.response
      const chat = response.data.data.chat
      yield put({
        type: CHAT_ADD_FORM_SAVE_FAILED
      })
      yield put(setExistingChat(chat))
    } else {
      yield put({
        type: CHAT_ADD_FORM_SAVE_FAILED,
        snackbar: {
          variant: 'error',
          message: getSnackbarErrorMessage(e)
        }
      })
    }
  }
}

export default function* chatAddSaga() {
  yield all([takeLatest(CHAT_ADD_FORM_SAVE_REQUESTED, addChat)])
}
