import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { bindActionCreators, compose } from 'redux'
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from '@material-ui/core'
import useAppDetector from '@doinn/shared/src/hooks/useAppDetector'
import Icon from '@doinn/shared/src/components/common/Icon'
import FlexSpacer from '@doinn/shared/src/components/common/FlexSpacer'
import HeaderUser from 'containers/app/components/HeaderUser'
import NotificationCounter from '@doinn/shared/src/components/app/NotificationCounter'
import MainMenuLogo from '@doinn/shared/src/components/app/MainMenuLogo'
import { setNotificationsCounter } from '@doinn/shared/src/containers/notifications/actions'
import { logout } from '@doinn/shared/src/containers/user/actions'
import { BASE_URL } from 'config/env'

const useStyles = makeStyles(theme => {
  const desktopBreakpoint = theme.breakpoints.up('md')
  const mobileBreakpoint = theme.breakpoints.down('xs')

  return {
    appBar: props => {
      const { isApp } = props
      const appBarStyles = {
        backgroundColor: theme.palette.background.default
      }

      if (!isApp) {
        appBarStyles[desktopBreakpoint] = {
          width: '100%'
        }
      }

      if (isApp) {
        appBarStyles[mobileBreakpoint] = {
          paddingTop: `env(safe-area-inset-top, 0)`
        }
      }

      return appBarStyles
    },
    appBarSpacer: props => {
      const appSpaceStyles = { ...theme.mixins.toolbar }

      if (props.isApp) {
        appSpaceStyles[mobileBreakpoint] = {
          minHeight: `calc(env(safe-area-inset-top, 0) + ${theme.mixins.toolbar.minHeight}px)`
        }
      }

      return appSpaceStyles
    },
    menuButton: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[10],
      marginLeft: 0,
      marginRight: theme.spacing(2),
      [desktopBreakpoint]: {
        display: 'none'
      }
    },
    divider: {
      height: theme.spacing(1.5),
      alignSelf: 'center',
      backgroundColor: theme.palette.text.primary
    },
    logoContainer: {
      transform: `scale(0.85) translateX(${theme.spacing(-1.5)}px)`
    }
  }
})

const Header = ({
  children,
  filtersContent,
  loggedUser,
  notificationsCounter,
  onLogout,
  onToggleMainMenu,
  setNotificationsCounter,
  title
}) => {
  const { t } = useTranslation()
  const isApp = useAppDetector()
  const isSite = !isApp
  const classes = useStyles({ isApp })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })
  const elevation = isScrolled ? 4 : 0

  const headerContent = !children ? (
    <Typography variant='h5'>
      <b>{title}</b>
    </Typography>
  ) : (
    children
  )

  const handleToggleMainMenu = () => {
    onToggleMainMenu && onToggleMainMenu()
  }

  return (
    <React.Fragment>
      <AppBar
        className={classes.appBar}
        color='inherit'
        elevation={elevation}
        id='appHeader'
        position='fixed'
      >
        <Toolbar>
          {isSite && !isDesktop && (
            <IconButton
              color='inherit'
              aria-label='open main menu'
              edge='start'
              onClick={handleToggleMainMenu}
              className={classes.menuButton}
            >
              <Icon icon='menu' />
            </IconButton>
          )}
          <div className={classes.logoContainer}>
            <MainMenuLogo />
          </div>
          {headerContent}
          <FlexSpacer />
          {isSite && isDesktop && (
            <Button
              color='primary'
              variant='outlined'
              size='small'
              href={`${BASE_URL}/admin/dashboard`}
            >
              {t('Go back to dashboard')}
            </Button>
          )}
          <Box pr={1}>
            <Grid container>
              {isMobile && !!filtersContent && (
                <React.Fragment>
                  {filtersContent}
                  <Divider
                    orientation='vertical'
                    flexItem
                    className={classes.divider}
                  />
                </React.Fragment>
              )}
              {isApp && (
                <NotificationCounter
                  counter={notificationsCounter}
                  onClick={setNotificationsCounter}
                />
              )}
            </Grid>
          </Box>
          {isSite && <HeaderUser loggedUser={loggedUser} onLogout={onLogout} />}
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer} />
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser,
  notificationsCounter: state.notifications.counter
})

const mapDispatchToProps = dispatch => ({
  onLogout: bindActionCreators(logout, dispatch),
  setNotificationsCounter: bindActionCreators(setNotificationsCounter, dispatch)
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header)
