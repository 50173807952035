import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles(theme => ({
  gutterBottom: {
    marginBottom: theme.spacing(1)
  }
}))

const LoadingText = props => {
  const theme = useTheme()
  const classes = useStyles()

  const tabletBreakpoint = theme.breakpoints.up('sm')
  const desktopBreakpoint = theme.breakpoints.up('md')
  const largeDesktopBreakpoint = theme.breakpoints.up('lg')

  const isTablet = useMediaQuery(tabletBreakpoint)
  const isDesktop = useMediaQuery(desktopBreakpoint)
  const isLargeDesktop = useMediaQuery(largeDesktopBreakpoint)

  let currentBreakpoint = ''
  if (isLargeDesktop) {
    currentBreakpoint = largeDesktopBreakpoint
  } else if (isDesktop) {
    currentBreakpoint = desktopBreakpoint
  } else if (isTablet) {
    currentBreakpoint = tabletBreakpoint
  }

  const typographyStyles =
    theme.typography[props.textVariant] || theme.typography.body2
  const fontSizeRem = currentBreakpoint
    ? typographyStyles[currentBreakpoint].fontSize
    : typographyStyles.fontSize

  let computedClassName = props.className
  computedClassName += props.gutterBottom ? ` ${classes.gutterBottom}` : ''

  return (
    <Skeleton
      component='span'
      variant='text'
      width={props.width}
      height={fontSizeRem}
      className={computedClassName}
    />
  )
}

LoadingText.propTypes = {
  textVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'overline',
    'button'
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]),
  gutterBottom: PropTypes.bool
}

LoadingText.defaultProps = {
  textVariant: 'body2',
  width: '60%'
}

export default LoadingText
