import React from 'react'
import { TextField as TextFieldMaterialUI } from '@material-ui/core'
import { Field } from 'formik'

class TextFieldComponent extends React.PureComponent {
  render() {
    const {
      field,
      form: { values, touched, errors },
      ...props
    } = this.props

    const isTouched = touched[field.name]
    const errorMessage = errors[field.name] || ''
    const displayError = !!(isTouched && errorMessage)
    const helperText = displayError ? errorMessage : ''
    const value = values[field.name]

    return (
      <TextFieldMaterialUI
        value={value}
        error={displayError}
        helperText={helperText}
        {...field}
        {...props}
      />
    )
  }
}

class TextField extends React.PureComponent {
  render() {
    return <Field component={TextFieldComponent} {...this.props} />
  }
}

TextField.defaultProps = {}

export default TextField
