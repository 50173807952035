import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import useAppVersionUpdate from '../../hooks/useAppVersionUpdate'

const NewReleaseNotification = () => {
  const { versionInfo, setVersionAsNotified } = useAppVersionUpdate()
  const { t } = useTranslation()
  const [showAppUpdateInfo, setShowAppUpdateInfo] = React.useState(
    versionInfo && !versionInfo.hasNotified
  )

  const handleClose = () => {
    setVersionAsNotified()
    setShowAppUpdateInfo(false)
  }

  return (
    <Snackbar
      open={showAppUpdateInfo}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity='success'>
        <AlertTitle>{t('Latests updates applied')}</AlertTitle>
        {t('The platform has been refreshed with new updates!')}
      </Alert>
    </Snackbar>
  )
}

export default NewReleaseNotification
