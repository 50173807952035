import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { isEmpty } from 'lodash'
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  makeStyles
} from '@material-ui/core'
import Icon from '@doinn/shared/src/components/common/Icon'
import { Link as RouterLink, withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => {
  return {
    stickToBottom: {
      width: '100%',
      paddingBottom: 'env(safe-area-inset-bottom, 0)',
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: theme.zIndex.drawer
    }
  }
})

const BottomMainMenu = ({ items, location }) => {
  const classes = useStyles()

  const allowedItems = items.filter(item => item.isAllowed)
  const forceShowLabels = allowedItems.length < 5

  const selectedItem = items.find(
    item => location.pathname.indexOf(item.link) === 0
  ) || { id: false }

  if (isEmpty(allowedItems)) {
    return null
  }

  return (
    <Paper
      id='appBottomMenu'
      aria-label='main menu'
      elevation={10}
      className={classes.stickToBottom}
    >
      <BottomNavigation value={selectedItem.id} showLabels={forceShowLabels}>
        {allowedItems.map((item, index) => (
          <BottomNavigationAction
            key={item.id}
            component={RouterLink}
            to={item.link}
            label={item.label}
            value={item.id}
            icon={
              item.badgeCounter ? (
                <Badge badgeContent={item.badgeCounter} color='primary'>
                  <Icon icon={item.icon} />
                </Badge>
              ) : (
                <Icon icon={item.icon} />
              )
            }
          />
        ))}
      </BottomNavigation>
    </Paper>
  )
}

BottomMainMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      isAllowed: PropTypes.bool.isRequired
    }).isRequired
  )
}

BottomMainMenu.defaultProps = {
  items: []
}

export default compose(withRouter)(BottomMainMenu)
