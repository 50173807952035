import convertToCamelCaseKeys from '@doinn/shared/src/util/convertToCamelCaseKeys'
import {
  CHAT_ADD_FORM_CLOSE_REQUESTED,
  CHAT_ADD_FORM_OPEN_REQUESTED,
  CHAT_ADD_FORM_SAVE_REQUESTED,
  CHAT_ADD_FORM_SET_EXISTING_CHAT
} from '@doinn/shared/src/containers/chats/add/constants'

export function openDialog() {
  return {
    type: CHAT_ADD_FORM_OPEN_REQUESTED
  }
}

export function closeDialog(params) {
  return {
    type: CHAT_ADD_FORM_CLOSE_REQUESTED,
    payload: { params }
  }
}

export function chatAddSave(params) {
  return {
    type: CHAT_ADD_FORM_SAVE_REQUESTED,
    payload: { params }
  }
}

export function setExistingChat(chat) {
  return {
    type: CHAT_ADD_FORM_SET_EXISTING_CHAT,
    payload: {
      chat: convertToCamelCaseKeys(chat)
    }
  }
}
