import React from 'react'
import { makeStyles } from '@material-ui/core'
import LogoImage from '@doinn/shared/src/components/app/imgs/doinn_logo_primary_2019.svg'

const useStyles = makeStyles(theme => ({
  logo: {
    height: theme.spacing(5)
  }
}))

const DoinnLogo = props => {
  const classes = useStyles(props)

  return <img className={classes.logo} src={LogoImage} alt='Doinn' />
}

export default DoinnLogo
