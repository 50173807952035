import React from 'react'
import { OutlinedInput } from '@material-ui/core'

// Set default props to Material-UI components
const props = {
  MuiButton: {
    color: 'primary'
  },
  MuiCheckbox: {
    color: 'primary'
  },
  MuiDialog: {
    maxWidth: 'sm'
  },
  MuiFab: {
    color: 'primary'
  },
  MuiFormControl: {
    variant: 'outlined',
    margin: 'normal',
    fullWidth: true
  },
  MuiRadio: {
    color: 'primary'
  },
  MuiCard: {
    elevation: 10
  },
  MuiPaper: {
    elevation: 10
  },
  MuiMenu: {
    elevation: 10
  },
  MuiSnackbarContent: {
    elevation: 10
  },
  MuiSelect: {
    color: 'secondary',
    variant: 'outlined',
    input: <OutlinedInput />
  },
  MuiSwitch: {
    color: 'primary'
  },
  MuiTextField: {
    color: 'secondary',
    variant: 'outlined'
  },
  MuiTypography: {
    variantMapping: {
      h1: 'div',
      h2: 'div',
      h3: 'div',
      h4: 'div',
      h5: 'div',
      h6: 'div',
      subtitle1: 'div',
      subtitle2: 'div',
      body1: 'div',
      body2: 'div',
      button: 'div',
      caption: 'div',
      overline: 'div'
    }
  },
  MuiSkeleton: {
    animation: 'pulse'
  }
}

export default props
