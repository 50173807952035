import { usePrevious as usePreviousAHooks } from 'ahooks'

const defaultShouldUpdate = () => {
  return true
}

const usePrevious = (state, shouldUpdate = defaultShouldUpdate) => {
  return usePreviousAHooks(state, shouldUpdate)
}

export default usePrevious
