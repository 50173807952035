import { all, call, put, takeLatest } from 'redux-saga/effects'
import { fetchChatsPropertiesApi } from '@doinn/shared/src/api/chats'
import {
  CHATS_PROPERTIES_FETCH_FAILED,
  CHATS_PROPERTIES_FETCH_MORE_FAILED,
  CHATS_PROPERTIES_FETCH_MORE_REQUESTED,
  CHATS_PROPERTIES_FETCH_MORE_SUCCEEDED,
  CHATS_PROPERTIES_FETCH_REQUESTED,
  CHATS_PROPERTIES_FETCH_SUCCEEDED
} from '@doinn/shared/src/containers/chats/properties/constants'
import { getSnackbarErrorMessage } from '@doinn/shared/src/containers/snackbar/util'

function* fetchChatsProperties(action) {
  try {
    const response = yield call(fetchChatsPropertiesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHATS_PROPERTIES_FETCH_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHATS_PROPERTIES_FETCH_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

function* fetchMoreChartsProperties(action) {
  try {
    const response = yield call(fetchChatsPropertiesApi, action.payload.params)
    const { data, meta } = response.data
    yield put({
      type: CHATS_PROPERTIES_FETCH_MORE_SUCCEEDED,
      payload: { data, meta }
    })
  } catch (e) {
    yield put({
      type: CHATS_PROPERTIES_FETCH_MORE_FAILED,
      snackbar: {
        variant: 'error',
        message: getSnackbarErrorMessage(e)
      }
    })
  }
}

export default function* chatsSaga() {
  yield all([
    takeLatest(CHATS_PROPERTIES_FETCH_REQUESTED, fetchChatsProperties),
    takeLatest(CHATS_PROPERTIES_FETCH_MORE_REQUESTED, fetchMoreChartsProperties)
  ])
}
