import axios from 'axios'
import { API_BASE_URL } from 'config/env'
import {
  camelizeResponseInterceptor,
  decamelizeRequestInterceptor,
  promiseRejectInterceptorError,
  tokenRequestInterceptor
} from '@doinn/shared/src/config/axiosInterceptors'

const DoinnApi = axios.create({
  baseURL: API_BASE_URL,
  timeout: parseInt('50000', 10)
})

// Converts all responses for CamelCase
DoinnApi.interceptors.response.use(
  camelizeResponseInterceptor,
  promiseRejectInterceptorError
)

DoinnApi.interceptors.request.use(
  decamelizeRequestInterceptor,
  promiseRejectInterceptorError
)

DoinnApi.interceptors.request.use(
  tokenRequestInterceptor,
  promiseRejectInterceptorError
)

export default DoinnApi
