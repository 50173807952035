// @flow
import React from 'react'
import { SvgIcon, withStyles } from '@material-ui/core'
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon'
import {
  mdiAccount,
  mdiAccountBoxMultipleOutline,
  mdiAccountCircle,
  mdiAccountEdit,
  mdiAccountMultiple,
  mdiAccountMultiplePlus,
  mdiAccountPlus,
  mdiAlert,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiArrowBottomRight,
  mdiArrowLeft,
  mdiArrowTopRight,
  mdiBed,
  mdiBell,
  mdiBookmarkCheck,
  mdiBroom,
  mdiCached,
  mdiCalendar,
  mdiChartLine,
  mdiCheck,
  mdiCheckCircle,
  mdiChevronDoubleUp,
  mdiChevronDown,
  mdiChevronDownCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronTripleUp,
  mdiChevronUp,
  mdiChevronUpCircle,
  mdiCircleMedium,
  mdiCircleSmall,
  mdiClock,
  mdiClockAlert,
  mdiClockOutline,
  mdiClose,
  mdiCloseCircle,
  mdiCog,
  mdiCurrencyUsdCircleOutline,
  mdiDelete,
  mdiDotsVertical,
  mdiDownload,
  mdiEmailSend,
  mdiEye,
  mdiEyeOutline,
  mdiFileDownload,
  mdiFilePdf,
  mdiFileUpload,
  mdiFilter,
  mdiFormatListChecks,
  mdiHelpCircle,
  mdiHome,
  mdiHomeHeart,
  mdiImage,
  mdiInformation,
  mdiInformationOutline,
  mdiLaunch,
  mdiLinkVariant,
  mdiLogin,
  mdiLogout,
  mdiMagnify,
  mdiMenu,
  mdiMenuDown,
  mdiMessage,
  mdiMessagePlus,
  mdiMessageProcessing,
  mdiMessageText,
  mdiMessageTextOutline,
  mdiMinus,
  mdiOfficeBuilding,
  mdiOpenInNew,
  mdiPaperclip,
  mdiPencil,
  mdiPencilOff,
  mdiPlay,
  mdiPlus,
  mdiPollBox,
  mdiTextBox,
  mdiTimelapse,
  mdiTimer,
  mdiTimerOff,
  mdiTrendingDown,
  mdiTrendingUp,
  mdiTshirtCrew,
  mdiUpload,
  mdiViewDashboard,
  mdiWashingMachine
} from '@mdi/js'
import { compose } from 'redux'

export const availableIcons = {
  account: mdiAccountCircle,
  account_box_multiple_outline: mdiAccountBoxMultipleOutline,
  add: mdiPlus,
  alert_circle: mdiAlertCircle,
  alert_circle_outline: mdiAlertCircleOutline,
  arrow_bottom_right: mdiArrowBottomRight,
  arrow_top_right: mdiArrowTopRight,
  attach: mdiPaperclip,
  automations: mdiCached,
  back: mdiArrowLeft,
  bell: mdiBell,
  bookmark_check: mdiBookmarkCheck,
  broom: mdiBroom,
  bullet: mdiCircleSmall,
  bullet_medium: mdiCircleMedium,
  calendar: mdiCalendar,
  cached: mdiCached,
  catalogs:
    'M19 3H5C4 3 3 4 3 5V19C3 20 4 21 5 21H19C20 21 21 20 21 19V5C21 4 20 3 19 3ZM19 19H5V7H19V19ZM16 12H8C7.45 12 7 11.55 7 11 7 10.45 7.45 10 8 10H16C16.55 10 17 10.45 17 11 17 11.55 16.55 12 16 12ZM12 16H8C7.45 16 7 15.55 7 15 7 14.45 7.45 14 8 14H12C12.55 14 13 14.45 13 15 13 15.55 12.55 16 12 16Z',
  check: mdiCheck,
  check_circle: mdiCheckCircle,
  checklists:
    'M11 7h6v2h-6zm0 4h6v2h-6zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7zM20.1 3H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM19 19H5V5h14v14z',
  chevron_down: mdiChevronDown,
  chevron_down_circle_outline: mdiChevronDownCircleOutline,
  chevron_left: mdiChevronLeft,
  chevron_right: mdiChevronRight,
  chevron_up: mdiChevronUp,
  chevron_up_circle: mdiChevronUpCircle,
  circle_small: mdiCircleSmall,
  clear: mdiCloseCircle,
  clock: mdiClock,
  clock_outline: mdiClockOutline,
  clock_alert: mdiClockAlert,
  close: mdiClose,
  cog: mdiCog,
  currency_usd_circle: mdiCurrencyUsdCircleOutline,
  dashboard: mdiViewDashboard,
  decrease: mdiMinus,
  delete: mdiDelete,
  dots_vertical: mdiDotsVertical,
  download: mdiDownload,
  edit: mdiPencil,
  edit_off: mdiPencilOff,
  email_send: mdiEmailSend,
  expand_less: mdiChevronUp,
  expand_more: mdiChevronDown,
  eye: mdiEye,
  eye_outline: mdiEyeOutline,
  file_pdf: mdiFilePdf,
  file_download: mdiFileDownload,
  file_upload: mdiFileUpload,
  filter: mdiFilter,
  format_list_checks: mdiFormatListChecks,
  help: mdiHelpCircle,
  home: mdiHome,
  home_heart: mdiHomeHeart,
  hotel: mdiBed,
  image: mdiImage,
  increase: mdiPlus,
  information: mdiInformation,
  information_outline: mdiInformationOutline,
  insights: mdiChartLine,
  integrations: mdiLinkVariant,
  invoices:
    'M19 3H5C3.9 3 3 3.9 3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5C21 3.9 20.1 3 19 3zM19 19H5V5h14V19zM7 12V17H9V12ZM15 7V17H17V7ZM11 14V17H13V14ZM11 10V12H13V10Z',
  jobs: mdiCalendar,
  launch: mdiLaunch,
  less: mdiMinus,
  login: mdiLogin,
  logout: mdiLogout,
  menu: mdiMenu,
  menu_down: mdiMenuDown,
  menu_options: mdiDotsVertical,
  message: mdiMessage,
  message_add: mdiMessagePlus,
  message_processing: mdiMessageProcessing,
  message_text: mdiMessageText,
  message_text_outline: mdiMessageTextOutline,
  next: mdiChevronRight,
  minus: mdiMinus,
  more: mdiPlus,
  notification: mdiBell,
  office_building: mdiOfficeBuilding,
  open_in_new: mdiOpenInNew,
  people: mdiAccountMultiple,
  people_add: mdiAccountMultiplePlus,
  person: mdiAccount,
  person_add: mdiAccountPlus,
  person_edit: mdiAccountEdit,
  pencil: mdiPencil,
  pencil_off: mdiPencilOff,
  photo: mdiImage,
  play: mdiPlay,
  plus: mdiPlus,
  poll_box: mdiPollBox,
  previous: mdiChevronLeft,
  priority_high: mdiChevronTripleUp,
  priority_low: mdiChevronUp,
  priority_normal: mdiChevronDoubleUp,
  profile: mdiAccountCircle,
  properties:
    'M17,11V3H7v4H3v14h8v-4h2v4h8V11H17z M7,19H5v-2h2V19z M7,15H5v-2h2V15z M7,11H5V9h2V11z M11,15H9v-2h2V15z M11,11H9V9h2 V11z M11,7H9V5h2V7z M15,15h-2v-2h2V15z M15,11h-2V9h2V11z M15,7h-2V5h2V7z M19,19h-2v-2h2V19z M19,15h-2v-2h2V15z',
  report: mdiPollBox,
  search: mdiMagnify,
  start_job: mdiTimer,
  status: mdiCircleMedium,
  settings: mdiCog,
  stop_job: mdiTimerOff,
  text_box: mdiTextBox,
  timelapse: mdiTimelapse,
  timer: mdiTimer,
  timer_off: mdiTimerOff,
  trending_down: mdiTrendingDown,
  trending_up: mdiTrendingUp,
  tshirt_crew: mdiTshirtCrew,
  upload: mdiUpload,
  warning: mdiAlert,
  washing_machine: mdiWashingMachine
}

// eslint-disable-next-line no-undef
export type IconType = $Keys<typeof availableIcons>

export type Props = {
  icon: IconType
} & SvgIconProps

const styles = theme => ({
  mirrored: {
    transform: 'scaleX(-1)'
  }
})

class Icon extends React.PureComponent<Props, void> {
  render() {
    const { classes, icon, mirrored, className, ...otherProps } = this.props
    const path = availableIcons[icon]
    if (!path) {
      console.error(`Could not find icon: "${icon}".`)
    }
    const iconClassName = mirrored
      ? `${className} ${classes.mirrored}`
      : className
    return (
      <SvgIcon {...otherProps} className={iconClassName}>
        <path d={path} />
      </SvgIcon>
    )
  }
}

Icon.muiName = 'SvgIcon'

export default compose(withStyles(styles))(Icon)
