import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import { Box, Container, CssBaseline, makeStyles } from '@material-ui/core'
import doinnMaterialUITheme from '@doinn/shared/src/config/theme'
import DoinnLogo from '@doinn/shared/src/components/app/DoinnLogo'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      background: theme.palette.common.white
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '100vh'
  },
  logoWrapper: {
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  logo: {
    height: 'auto',
    width: '160px'
  }
}))

const TemplateAuth = props => {
  const { children } = props
  const classes = useStyles()

  return (
    <ThemeProvider theme={doinnMaterialUITheme}>
      <CssBaseline />
      <Container>
        <Box className={classes.content}>
          <div className={classes.logoWrapper}>
            <DoinnLogo classes={{ logo: classes.logo }} />
          </div>
          {children}
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default TemplateAuth
