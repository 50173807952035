export const CHATS_PROPERTIES_FETCH_REQUESTED =
  'CHATS_PROPERTIES_FETCH_REQUESTED'
export const CHATS_PROPERTIES_FETCH_SUCCEEDED =
  'CHATS_PROPERTIES_FETCH_SUCCEEDED'
export const CHATS_PROPERTIES_FETCH_FAILED = 'CHATS_PROPERTIES_FETCH_FAILED'

export const CHATS_PROPERTIES_FETCH_MORE_REQUESTED =
  'CHATS_PROPERTIES_FETCH_MORE_REQUESTED'
export const CHATS_PROPERTIES_FETCH_MORE_SUCCEEDED =
  'CHATS_PROPERTIES_FETCH_MORE_SUCCEEDED'
export const CHATS_PROPERTIES_FETCH_MORE_FAILED =
  'CHATS_PROPERTIES_FETCH_MORE_FAILED'
