import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withEcho } from '@doinn/shared/src/hoc/echo'
import { usePrevious, useWillUnmount } from '@doinn/shared/src/hooks'
import { ECHO_EVENT_NOTIFICATIONS_COUNTER } from '@doinn/shared/src/hoc/echo/constants'
import { setNotificationsCounter } from '@doinn/shared/src/containers/notifications/actions'

const NotificationCounterListener = ({
  echoUserChannel,
  setNotificationsCounter
}) => {
  const [isListening, setIsListening] = React.useState(false)
  const previousEchoUserChannel = usePrevious(echoUserChannel)

  const handleEchoUpdates = useCallback(() => {
    if (echoUserChannel && !isListening) {
      echoUserChannel.listen(ECHO_EVENT_NOTIFICATIONS_COUNTER, data => {
        const counter = data ? data.data : 0
        const newTotalNotifications = counter > 0 ? counter : 0
        setNotificationsCounter(newTotalNotifications)
      })
      setIsListening(true)
    }
  }, [echoUserChannel, isListening, setNotificationsCounter, setIsListening])

  const stopListeningEchoUpdate = useCallback(
    echoChannel => {
      if (echoChannel && isListening) {
        echoChannel.stopListening(ECHO_EVENT_NOTIFICATIONS_COUNTER)
        setIsListening(false)
      }
    },
    [isListening, setIsListening]
  )

  React.useEffect(() => {
    handleEchoUpdates()
  }, [handleEchoUpdates])

  React.useEffect(() => {
    if (previousEchoUserChannel && !echoUserChannel) {
      stopListeningEchoUpdate(previousEchoUserChannel)
    }
  }, [previousEchoUserChannel, echoUserChannel, stopListeningEchoUpdate])

  useWillUnmount(() => {
    stopListeningEchoUpdate(echoUserChannel)
  })

  return null
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  setNotificationsCounter: bindActionCreators(setNotificationsCounter, dispatch)
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEcho
)(NotificationCounterListener)
