import React from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import TextField from '@doinn/shared/src/components/common/inputs/TextField'
import styles from '@doinn/shared/src/containers/auth/components/styles'
import { loginValidationSchema } from '@doinn/shared/src/containers/auth/validation'

const useStyles = makeStyles(styles)

const LoginForm = ({ error, history, isLoading, onSubmit }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const handleFormSubmit = values => onSubmit(values.email, values.password)
  const handleRecoverPasswordClick = e => {
    e.preventDefault()
    history.push('/recover-password')
  }

  return (
    <div>
      <Typography className={classes.text}>
        {t('The tech bridge between property managers and service providers.')}
      </Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {props => {
          const { handleSubmit } = props

          return (
            <form
              className={classes.form}
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit}
            >
              <Box mb={3}>
                <TextField
                  fullWidth
                  id='email'
                  name='email'
                  label={t('Email')}
                  type='email'
                />
                <TextField
                  fullWidth
                  name='password'
                  label={t('Password')}
                  type='password'
                />
              </Box>
              {error && (
                <Box mb={3}>
                  <Typography className={classes.error} variant='body2'>
                    {error}
                  </Typography>
                </Box>
              )}
              <Button
                disabled={isLoading}
                fullWidth
                size='large'
                type='submit'
                variant='contained'
              >
                {t('I have an account, Sign In')}
              </Button>
              <Button
                fullWidth
                size='large'
                onClick={handleRecoverPasswordClick}
              >
                {t('I forgot the password')}
              </Button>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default compose(withRouter)(LoginForm)
