import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { allRoutes } from 'routes'
import { find } from 'lodash'

const getRoutePath = routeKey => {
  const route = find(allRoutes, {
    key: routeKey
  })
  return route.path
}

const AppRedirects = ({ loggedUser }) => {
  const loginRoutePath = getRoutePath('Login')

  if (!loggedUser) {
    return <Redirect to={loginRoutePath} />
  }

  return null
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser
})

const mapDispatchToProps = dispatch => ({})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AppRedirects
)
