import React from 'react'
import { Box, Fade, Typography, makeStyles } from '@material-ui/core'
import DefaultImage from '@doinn/shared/src/components/common/empty-state/svgs/shining.svg'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3)
  },
  image: {
    height: theme.spacing(16),
    width: theme.spacing(16),
    marginBottom: theme.spacing(2)
  }
}))

const EmptyState = ({ image, title, description }) => {
  const classes = useStyles()
  const initialIsLoaded = !image
  const [isLoaded, setIsLoaded] = React.useState(initialIsLoaded)

  const handleImageLoaded = () => {
    setIsLoaded(true)
  }

  return (
    <Fade in={isLoaded}>
      <Box className={classes.container} textAlign='center'>
        {image && (
          <img
            src={image}
            alt=''
            className={classes.image}
            onLoad={handleImageLoaded}
          />
        )}
        <Typography component='div' variant='subtitle1'>
          {title}
        </Typography>
        {description && <Typography component='div'>{description}</Typography>}
      </Box>
    </Fade>
  )
}

EmptyState.defaultProps = {
  image: DefaultImage,
  title: 'This is empty',
  description: ''
}

export default EmptyState
