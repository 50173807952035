import { all } from 'redux-saga/effects'
import i18n from '@doinn/shared/src/config/i18n'
import { DEFAULT_LANGUAGE_CODE } from 'config/env'
import appSagas from '@doinn/shared/src/containers/app/sagas'
import authSagas from '@doinn/shared/src/containers/auth/sagas'
import chatsSagas from '@doinn/shared/src/containers/chats/sagas'
import chatsContactsSagas from '@doinn/shared/src/containers/chats/contacts/sagas'
import chatsJobsSagas from '@doinn/shared/src/containers/chats/jobs/sagas'
import chatsPropertiesSagas from '@doinn/shared/src/containers/chats/properties/sagas'
import chatAddSagas from '@doinn/shared/src/containers/chats/add/sagas'
import chatFollowSagas from '@doinn/shared/src/containers/chats/follow/sagas'
import chatGetSagas from '@doinn/shared/src/containers/chats/get/sagas'
import chatMessagesSagas from '@doinn/shared/src/containers/chats/messages/sagas'
import cityListSagas from '@doinn/shared/src/containers/city/list/sagas'
import propertyTypesSagas from '@doinn/shared/src/containers/property-types/sagas'
import userSagas from '@doinn/shared/src/containers/user/sagas'
import languageSagas from '@doinn/shared/src/containers/language/sagas'
import notificationsSagas from '@doinn/shared/src/containers/notifications/sagas'

export default function* rootSaga() {
  yield all([
    appSagas(),
    authSagas(),
    chatsSagas(),
    chatsContactsSagas(),
    chatsJobsSagas(),
    chatsPropertiesSagas(),
    chatMessagesSagas(),
    chatAddSagas(),
    chatFollowSagas(),
    chatGetSagas(),
    chatFollowSagas(),
    cityListSagas(),
    languageSagas(DEFAULT_LANGUAGE_CODE, language =>
      i18n.changeLanguage(language)
    ),
    notificationsSagas(),
    propertyTypesSagas(),
    userSagas()
  ])
}
