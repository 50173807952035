import Cookies from 'js-cookie'
import DoinnApi from '@doinn/shared/src/config/doinnApi'
import { SESSION_DOMAIN, WEB_TOKEN_COOKIE_NAME } from 'config/env'

export function getCurrentAuthTokenApi() {
  const currentLocalStorageAuthToken = window.localStorage.getItem('authToken')
  const currentCookieAuthToken = Cookies.get(WEB_TOKEN_COOKIE_NAME)
  const authToken =
    currentCookieAuthToken || currentLocalStorageAuthToken || null

  if (currentCookieAuthToken) {
    window.localStorage.setItem('authToken', authToken)
    Cookies.remove(WEB_TOKEN_COOKIE_NAME, {
      path: '/',
      domain: `${SESSION_DOMAIN}`
    })
  }

  return authToken
}

export function authLoginApi(email, password) {
  const config = {}
  const credentials = { email, password }
  return DoinnApi.post(`/login`, credentials, config)
}

export function authLogoutApi() {
  const config = {}
  return DoinnApi.delete(`/logout`, config)
}

export function recoverPasswordApi(email) {
  const config = {}
  return DoinnApi.post(`/password/recover`, { email }, config)
}
