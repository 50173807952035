import { Capacitor } from '@capacitor/core'
import { Intercom } from '@capacitor-community/intercom'
import { APP_NAME, INTERCOM_MOBILE_ENABLED } from 'config/env'

export const init = async user => {
  if (INTERCOM_MOBILE_ENABLED && Capacitor.isNativePlatform() && user.id) {
    await Intercom.logout()
    await Intercom.registerIdentifiedUser({ email: user.email })

    if (user.intercomIdentityVerificationHash) {
      const intercomHash =
        Capacitor.getPlatform() === 'ios'
          ? user.intercomIdentityVerificationHash.ios
          : user.intercomIdentityVerificationHash.android
      await Intercom.setUserHash({ hmac: intercomHash })
    }

    await Intercom.updateUser({
      userId: user.id,
      email: user.email,
      name: user.name,
      phone: user?.mobilephone,
      languageOverride: user?.preferences?.language,
      customAttributes: {
        userId: user.id,
        language: user?.preferences?.language,
        hostapp: APP_NAME === '@doinn/host',
        vendorapp: APP_NAME === '@doinn/vendor',
        createdAt: user?.createdAt,
        mainCityOfOperations: user?.mainCity,
        vendorOnboardingStatus: user?.business?.vendorOnboarding?.status,
        vendorOnboardingStep: user?.business?.vendorOnboarding?.currentStep,
        stripeOnboardingStatus: user?.business?.stripe?.status,
        businessName: user?.business?.name,
        isApproved: user?.business?.isApproved,
        isDoinn: user?.business?.isDoinn,
        isSaas: user?.business?.isSaas
      }
    })
  }
}
