const envVars = process.env

export const APP_ENV = envVars.REACT_APP_APP_ENVIRONMENT
export const APP_NAME = envVars.REACT_APP_NAME
export const APP_SCOPE = envVars.REACT_APP_SCOPE
export const BASE_URL = envVars.REACT_APP_BASE_URL
export const BASE_SITE_URL = envVars.REACT_APP_BASE_SITE_URL
export const API_KEY = envVars.REACT_APP_API_KEY
export const API_BASE_URL = envVars.REACT_APP_API_BASE_URL
export const ELASTICSEARCH_BASE_URL = envVars.REACT_APP_ELASTICSEARCH_BASE_URL
export const SESSION_DOMAIN = envVars.REACT_APP_SESSION_DOMAIN
export const WEB_TOKEN_COOKIE_NAME = envVars.REACT_APP_WEB_TOKEN_COOKIE_NAME
export const MOBILE_ONLY = envVars.REACT_APP_MOBILE_ONLY === 'true'
export const GOOGLE_ANALYTICS = envVars.REACT_APP_GOOGLE_ANALYTICS
export const SEGMENT_ANALYTICS_IDENTIFY =
  envVars.REACT_APP_SEGMENT_ANALYTICS_IDENTIFY === 'true'
export const PUSHER_CONFIG = {
  key: envVars.REACT_APP_PUSHER_CONFIG_KEY,
  authenticationPath: envVars.REACT_APP_PUSHER_CONFIG_AUTHENTICATION_PATH,
  tls: envVars.REACT_APP_PUSHER_CONFIG_TLS,
  cluster: envVars.REACT_APP_PUSHER_CONFIG_CLUSTER,
  beams: {
    instanceId: envVars.REACT_APP_PUSHER_BEAMS_INSTANCE_ID,
    tokenProviderPath: envVars.REACT_APP_PUSHER_BEAMS_TOKEN_PROVIDER_PATH
  }
}
export const SENTRY_URL = envVars.REACT_APP_SENTRY_URL
export const DEFAULT_DATE_FORMAT =
  envVars.REACT_APP_DEFAULT_DATE_FORMAT || 'DD-MM-YYYY'
export const API_DATE_FORMAT = envVars.REACT_APP_API_DATE_FORMAT || 'YYYY-MM-DD'
export const DEFAULT_LANGUAGE_CODE =
  envVars.REACT_APP_DEFAULT_LANGUAGE_CODE || 'en_US'
export const ORDER_SERVICE_NOTE_PHOTOS_LIMIT =
  parseInt(envVars.REACT_APP_ORDER_SERVICE_NOTE_PHOTOS_LIMIT, 10) || 10
export const CHAT_NAME_MAX_CHARS =
  parseInt(envVars.REACT_APP_CHAT_NAME_MAX_CHARS, 10) || 50
export const AUTOMATION_NAME_MAX_CHARS =
  parseInt(envVars.REACT_APP_AUTOMATION_NAME_MAX_CHARS, 10) || 50
export const CHAT_MESSAGE_PHOTOS_LIMIT =
  parseInt(envVars.REACT_APP_CHAT_MESSAGE_PHOTOS_LIMIT, 10) || 10
export const INTERCOM_MOBILE_ENABLED =
  envVars.REACT_APP_INTERCOM_MOBILE_ENABLED === 'true'
