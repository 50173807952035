import { useCallback, useEffect, useState } from 'react'
import { cloneDeep, isEqual as isObjEqual } from 'lodash'
import { usePrevious } from '@doinn/shared/src/hooks'

/**
 * @param {Object} [initialFilters={}]
 * @returns {Object}
 */
const useFilterState = (initialFilters = {}) => {
  const [appliedFilters, setAppliedFilters] = useState(initialFilters)
  const [selectedFilters, setSelectedFilters] = useState(initialFilters)
  const [hasAppliedFiltersChanges, setHasAppliedFiltersChanges] =
    useState(false)
  const previousAppliedFilters = usePrevious(appliedFilters)
  const previousInitialFilters = usePrevious(initialFilters)

  useEffect(() => {
    setHasAppliedFiltersChanges(
      !isObjEqual(appliedFilters, previousAppliedFilters)
    )
  }, [appliedFilters, previousAppliedFilters])

  useEffect(() => {
    if (isObjEqual(previousInitialFilters, initialFilters)) return
    setAppliedFilters(initialFilters)
    setSelectedFilters(initialFilters)
  }, [initialFilters, previousInitialFilters])

  const onChangeAppliedFilters = useCallback(
    (newFilters = initialFilters) => {
      setAppliedFilters(cloneDeep({ ...selectedFilters, ...newFilters }))
    },
    [initialFilters, selectedFilters]
  )

  const onChangeSelectedFilters = useCallback(
    (newFilters = initialFilters) => {
      setSelectedFilters(
        cloneDeep({
          ...initialFilters,
          ...newFilters
        })
      )
    },
    [initialFilters]
  )

  const onClearSelectedFilters = useCallback(() => {
    setSelectedFilters(initialFilters)
  }, [initialFilters])

  const onConfirmSelectedFilters = useCallback(() => {
    setAppliedFilters(cloneDeep({ ...selectedFilters }))
  }, [selectedFilters])

  return {
    initialFilters,
    appliedFilters,
    selectedFilters,
    hasAppliedFiltersChanges,
    previousAppliedFilters,
    onChangeAppliedFilters,
    onChangeSelectedFilters,
    onClearSelectedFilters,
    onConfirmSelectedFilters
  }
}

export default useFilterState
