import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

const VirtualListLoading = ({ children, gutter, items }) => {
  return [...Array(items)].map((value, index) => (
    <Box key={index} mb={gutter}>
      {children}
    </Box>
  ))
}

VirtualListLoading.propTypes = {
  items: PropTypes.number
}

VirtualListLoading.defaultProps = {
  items: 3
}

export default VirtualListLoading
