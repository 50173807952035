import {
  APP_START_FAILED,
  APP_START_REQUESTED,
  APP_START_SUCCEEDED
} from '@doinn/shared/src/containers/app/constants'

export const initialState = {
  isLoadingApp: true,
  isFailedApp: false
}

export default (state = initialState, action) => {
  const { type } = action
  switch (type) {
    case APP_START_REQUESTED:
      return {
        ...state,
        ...initialState,
        isLoadingApp: true,
        isFailedApp: false
      }
    case APP_START_SUCCEEDED:
      return {
        ...state,
        isLoadingApp: false,
        isFailedApp: false
      }
    case APP_START_FAILED:
      return {
        ...state,
        isLoadingApp: false,
        isFailedApp: true
      }
    default:
      return state
  }
}
