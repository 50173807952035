import ReactGA from 'react-ga'
import { get, isFunction } from 'lodash'
import { USER_GET_SUCCEEDED } from '@doinn/shared/src/containers/user/constants'

const EVENTS_IGNORED = ['@@router/LOCATION_CHANGE']

const EVENTS_MAPPED = {
  [USER_GET_SUCCEEDED]: action => {
    ReactGA.set({
      userId: get(action, 'payload.data.id')
    })
  }
}

const extractCategoryFromType = type => {
  const regexResult = type.match(/^([a-zA-Z\s])+/)
  return regexResult && regexResult[0]
}

export default store => next => action => {
  const { type } = action
  const category =
    action.category || extractCategoryFromType(type) || 'MHK Events'
  const eventMapped = EVENTS_MAPPED[type]

  if (eventMapped) {
    isFunction(eventMapped) ? eventMapped(action) : ReactGA.event(eventMapped)
  } else if (EVENTS_IGNORED.indexOf(type) === -1) {
    ReactGA.event({
      category,
      action: type
    })
  }

  return next(action)
}
