import { camelCase, isArray, isObject, reduce } from 'lodash'

export default function convertToCamelCaseKeys(obj) {
  if (!isObject(obj)) {
    return obj
  }

  if (isArray(obj)) {
    return obj.map(value => convertToCamelCaseKeys(value))
  }

  return reduce(
    obj,
    (carry, value, key) => ({
      ...carry,
      [camelCase(key)]: convertToCamelCaseKeys(value)
    }),
    {}
  )
}
