import {
  HIDE_SNACKBAR,
  RESET_SNACKBAR,
  SHOW_SNACKBAR
} from '@doinn/shared/src/containers/snackbar/constants'

export function showSnackbar(params) {
  return { type: SHOW_SNACKBAR, payload: params }
}

export function hideSnackbar() {
  return { type: HIDE_SNACKBAR }
}

export function resetSnackbar() {
  return { type: RESET_SNACKBAR }
}
