import Pusher from 'pusher-js' // eslint-disable-line no-unused-vars
import React from 'react'
import Echo from 'laravel-echo'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getCurrentAuthTokenApi } from '@doinn/shared/src/api/auth'

export const EchoContext = React.createContext('echo')

const Provider = ({ apiKey, apiBaseUrl, config, loggedUser, children }) => {
  const [echo, setEcho] = React.useState(null)
  const [echoUserChannel, setEchoUserChannel] = React.useState(null)

  React.useEffect(() => {
    const authToken = getCurrentAuthTokenApi()

    if (!authToken) {
      return setEchoUserChannel(null)
    }

    const options = {
      broadcaster: 'pusher',
      key: config.key,
      cluster: config.cluster,
      forceTLS: true,
      authEndpoint: `${apiBaseUrl}${config.authenticationPath}`,
      auth: {
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          'api-key': apiKey
        }
      }
    }

    if (loggedUser) {
      const echo = new Echo(options)
      setEcho(echo)
      setEchoUserChannel(echo.private(`App.Models.User.${loggedUser.id}`))
    }
  }, [
    apiKey,
    apiBaseUrl,
    config.cluster,
    config.key,
    config.authenticationPath,
    loggedUser
  ])

  return (
    <EchoContext.Provider value={{ echo, echoUserChannel }}>
      {children}
    </EchoContext.Provider>
  )
}

const mapStateToProps = state => ({
  loggedUser: state.user.loggedUser
})

const mapDispatchToProps = dispatch => ({})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Provider)
