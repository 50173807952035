import React from 'react'
import { compose } from 'redux'
import { Box } from '@material-ui/core'
import LoadingText from '@doinn/shared/src/components/common/loading/LoadingText'
import NotificationsListItemLoading from '@doinn/shared/src/containers/notifications/ListItemLoading'

const NotificationsListLoading = () => {
  return (
    <div>
      <Box py={2}>
        <LoadingText textVariant='body2' width={48} />
      </Box>
      {[...Array(3)].map((value, indexRow) => (
        <NotificationsListItemLoading key={indexRow} />
      ))}
    </div>
  )
}

NotificationsListLoading.propTypes = {}

export default compose()(NotificationsListLoading)
