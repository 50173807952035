// @flow
/**
 * Based on https://github.com/alibaba/hooks/blob/master/packages/hooks/src/useUnmount/index.ts
 */
import { useEffect, useRef } from 'react'

const useWillUnmount = (fn: any) => {
  const fnRef = useRef(fn)
  fnRef.current = fn

  useEffect(
    () => () => {
      if (fnRef.current && typeof fnRef.current === 'function') {
        fnRef.current()
      }
    },
    []
  )
}

export default useWillUnmount
