import { Capacitor } from '@capacitor/core'
import { SEGMENT_ANALYTICS_IDENTIFY } from 'config/env'
import { isHideDefaultLauncher } from '@doinn/shared/src/util/intercom'

export const init = user => {
  if (
    SEGMENT_ANALYTICS_IDENTIFY &&
    !Capacitor.isNativePlatform() &&
    window?.analytics?.identify &&
    user.id
  ) {
    window.analytics.identify(
      user.id,
      {
        name: user?.name,
        email: user?.email,
        phone: user?.mobilephone,
        vendorapp: 'true',
        language: user?.preferences?.language,
        createdAt: user?.createdAt,
        mainCityOfOperations: user?.mainCity,
        vendorOnboardingStatus: user?.business?.vendorOnboarding?.status,
        vendorOnboardingStep: user?.business?.vendorOnboarding?.currentStep,
        stripeOnboardingStatus: user?.business?.stripe?.status,
        businessName: user?.business?.name,
        isApproved: user?.business?.isApproved,
        isDoinn: user?.business?.isDoinn,
        isSaas: user?.business?.isSaas
      },
      {
        Intercom: { hideDefaultLauncher: isHideDefaultLauncher() }
      }
    )
  }
}
