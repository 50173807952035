// @flow
import {
  format as formatDateFns,
  intervalToDuration,
  isDate,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  parse as parseDate,
  parseISO as parseISODate
} from 'date-fns'
import { enGB, es, fr, pt } from 'date-fns/locale'
import i18n from '@doinn/shared/src/config/i18n'

const DEFAULT_FORMAT_SIZE = 'medium'

export type FormatSizeType =
  | 'full'
  | 'long'
  | 'longDm'
  | 'longDmWeek'
  | 'medium'
  | 'mediumDm'
  | 'mediumDmWeek'
  | 'short'
export type LocaleType = 'en' | 'es' | 'fr' | 'pt' | 'system'

export const locales = {
  en: enGB,
  es,
  fr,
  pt,
  system: enGB
}

const dateFormats = {
  en: {
    full: 'EEEE, d MMMM yyyy',
    long: 'd MMMM yyyy',
    longDm: 'd MMMM',
    longDmWeek: 'EEEE, d MMMM',
    longMonth: 'MMMM yyyy',
    medium: 'd MMM yyyy',
    mediumMonth: 'MMM yyyy',
    mediumDm: 'd MMM',
    mediumDmWeek: 'EEEE, d MMM',
    short: 'dd/MM/yyyy'
  },
  es: {
    full: "EEEE, d 'de' MMMM yyyy",
    long: "d 'de' MMMM yyyy",
    longDm: "d 'de' MMMM",
    longDmWeek: "EEEE, d 'de' MMMM",
    longMonth: 'MMMM yyyy',
    medium: 'd MMM yyyy',
    mediumMonth: 'MMM yyyy',
    mediumDm: 'd MMM',
    mediumDmWeek: 'EEEE, d MMM',
    short: 'dd/MM/yyyy'
  },
  fr: {
    full: 'EEEE d MMMM yyyy',
    long: 'd MMMM yyyy',
    longDm: 'd MMMM',
    longDmWeek: 'EEEE d MMMM',
    longMonth: 'MMMM yyyy',
    medium: 'd MMM yyyy',
    mediumMonth: 'MMM yyyy',
    mediumDm: 'd MMM',
    mediumDmWeek: 'EEEE d MMM',
    short: 'dd/MM/yyyy'
  },
  pt: {
    full: "EEEE, d 'de' MMMM 'de' yyyy",
    long: "d 'de' MMMM 'de' yyyy",
    longDm: "d 'de' MMMM",
    longDmWeek: "EEEE, d 'de' MMMM",
    longMonth: "MMMM 'de' yyyy",
    medium: "d 'de' MMM 'de' yyyy",
    mediumMonth: "MMM 'de' yyyy",
    mediumDm: "d 'de' MMM",
    mediumDmWeek: "EEEE, d 'de' MMM",
    short: 'dd/MM/yyyy'
  },
  system: {
    full: 'yyyy-MM-dd',
    long: 'yyyy-MM-dd',
    longDm: 'yyyy-MM-dd',
    longDmWeek: 'yyyy-MM-dd',
    longMonth: 'yyyy-MM-dd',
    medium: 'yyyy-MM-dd',
    mediumMonth: 'yyyy-MM-dd',
    mediumDm: 'yyyy-MM-dd',
    mediumDmWeek: 'yyyy-MM-dd',
    short: 'yyyy-MM-dd'
  }
}

const timeFormats = {
  en: {
    full: 'HH:mm:ss z',
    long: 'HH:mm:ss',
    medium: 'HH:mm',
    short: 'HH:mm'
  },
  es: {
    full: 'HH:mm:ss z',
    long: 'HH:mm:ss',
    medium: 'HH:mm',
    short: 'HH:mm'
  },
  fr: {
    full: 'HH:mm:ss z',
    long: 'HH:mm:ss',
    medium: 'HH:mm',
    short: 'HH:mm'
  },
  pt: {
    full: 'HH:mm:ss z',
    long: 'HH:mm:ss',
    medium: 'HH:mm',
    short: 'HH:mm'
  },
  system: {
    full: 'HH:mm:ssxxx',
    long: 'HH:mm:ss',
    medium: 'HH:mm',
    short: 'HH:mm'
  }
}

const dateTimeFormats = {
  en: {
    full: `${dateFormats.en.full} 'at' ${timeFormats.en.full}`,
    long: `${dateFormats.en.long} 'at' ${timeFormats.en.long}`,
    longDm: `${dateFormats.en.longDm} 'at' ${timeFormats.en.long}`,
    longDmWeek: `${dateFormats.en.longDmWeek} 'at' ${timeFormats.en.long}`,
    medium: `${dateFormats.en.medium}, ${timeFormats.en.medium}`,
    mediumDm: `${dateFormats.en.mediumDm}, ${timeFormats.en.medium}`,
    mediumDmWeek: `${dateFormats.en.mediumDmWeek}, ${timeFormats.en.medium}`,
    short: `${dateFormats.en.short}, ${timeFormats.en.short}`
  },
  es: {
    full: `${dateFormats.es.full} 'a las' ${timeFormats.es.full}`,
    long: `${dateFormats.es.long} 'a las' ${timeFormats.es.long}`,
    longDm: `${dateFormats.es.longDm} 'a las' ${timeFormats.es.long}`,
    longDmWeek: `${dateFormats.es.longDmWeek} 'a las' ${timeFormats.es.long}`,
    medium: `${dateFormats.es.medium}, ${timeFormats.es.medium}`,
    mediumDm: `${dateFormats.es.mediumDm}, ${timeFormats.es.medium}`,
    mediumDmWeek: `${dateFormats.es.mediumDmWeek}, ${timeFormats.es.medium}`,
    short: `${dateFormats.es.short}, ${timeFormats.es.short}`
  },
  fr: {
    full: `${dateFormats.fr.full} 'à' ${timeFormats.fr.full}`,
    long: `${dateFormats.fr.long} 'à' ${timeFormats.fr.long}`,
    longDm: `${dateFormats.fr.longDm} 'à' ${timeFormats.fr.long}`,
    longDmWeek: `${dateFormats.fr.longDmWeek} 'à' ${timeFormats.fr.long}`,
    medium: `${dateFormats.fr.medium}, ${timeFormats.fr.medium}`,
    mediumDm: `${dateFormats.fr.mediumDm}, ${timeFormats.fr.medium}`,
    mediumDmWeek: `${dateFormats.fr.mediumDmWeek}, ${timeFormats.fr.medium}`,
    short: `${dateFormats.fr.short}, ${timeFormats.fr.short}`
  },
  pt: {
    full: `${dateFormats.pt.full} 'às' ${timeFormats.pt.full}`,
    long: `${dateFormats.pt.long} 'às' ${timeFormats.pt.long}`,
    longDm: `${dateFormats.pt.longDm} 'às' ${timeFormats.pt.long}`,
    longDmWeek: `${dateFormats.pt.longDmWeek} 'às' ${timeFormats.pt.long}`,
    medium: `${dateFormats.pt.medium}, ${timeFormats.pt.medium}`,
    mediumDm: `${dateFormats.pt.mediumDm}, ${timeFormats.pt.medium}`,
    mediumDmWeek: `${dateFormats.pt.mediumDmWeek}, ${timeFormats.pt.medium}`,
    short: `${dateFormats.pt.short}, ${timeFormats.pt.short}`
  },
  system: {
    full: `${dateFormats.system.full}'T'${timeFormats.system.full}`,
    long: `${dateFormats.system.long}'T'${timeFormats.system.long}`,
    longDm: `${dateFormats.system.longDm}'T'${timeFormats.system.long}`,
    longDmWeek: `${dateFormats.system.longDmWeek}'T'${timeFormats.system.long}`,
    medium: `${dateFormats.system.medium}'T'${timeFormats.system.medium}`,
    mediumDm: `${dateFormats.system.mediumDm}'T'${timeFormats.system.medium}`,
    mediumDmWeek: `${dateFormats.system.mediumDmWeek}'T'${timeFormats.system.medium}`,
    short: `${dateFormats.system.short}'T'${timeFormats.system.short}`
  }
}

const formats = {
  date: dateFormats,
  time: timeFormats,
  datetime: dateTimeFormats
}

export const parseLocale = (locale: LocaleType) => {
  if (locale) return locale
  try {
    return window.__localeId__ || 'en'
  } catch (e) {
    return 'en'
  }
}

export const parseFormatStr = (formatStr = '', locale: LocaleType) => {
  if (
    formatStr.indexOf('date') === 0 ||
    formatStr.indexOf('time') === 0 ||
    formatStr.indexOf('datetime') === 0
  ) {
    const [format, size] = formatStr.split('.')
    const formatSize = size || DEFAULT_FORMAT_SIZE
    const parsedLocale = parseLocale(locale)
    return formats[format][parsedLocale][formatSize]
  }
  return formatStr
}

const getFormatFunction = () => (date, formatStr, locale: LocaleType) => {
  const parsedLocale = parseLocale(locale)
  const parsedFormatStr = parseFormatStr(formatStr, locale)
  const parsedDate = isDate(date) ? date : parseISODate(date)
  return formatDateFns(parsedDate, parsedFormatStr, {
    locale: locales[parsedLocale]
  })
}

export const formatDate = (
  date,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE,
  locale: LocaleType
) => {
  return format(date, `date.${formatSize}`, locale)
}

export const formatTime = (
  date,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE,
  locale: LocaleType
) => {
  return format(date, `time.${formatSize}`, locale)
}

export const formatTimeFromMilitaryTime = (
  time,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE,
  locale: LocaleType
) => {
  const date = parseFromMilitaryTime(time)
  return formatTime(date, formatSize, locale)
}

export const formatDateTime = (
  date,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE,
  locale: LocaleType
) => {
  return format(date, `datetime.${formatSize}`, locale)
}

export const formatSystemDate = (
  date,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE
) => {
  return format(date, `date.${formatSize}`, 'system')
}

export const formatSystemTime = (
  date,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE
) => {
  return format(date, `time.${formatSize}`, 'system')
}

export const formatSystemDateTime = (
  date,
  formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE
) => {
  return format(date, `datetime.${formatSize}`, 'system')
}

export const parseFromMilitaryTime = time => {
  return parseDate(time, 'HHmm', new Date())
}

const getFormatDayNameFunction =
  () =>
  (
    date,
    formatSize: FormatSizeType = DEFAULT_FORMAT_SIZE,
    locale: LocaleType
  ) => {
    if (!isValid(date)) return String(date)
    if (isYesterday(date)) return i18n.t('Yesterday')
    if (isToday(date)) return i18n.t('Today')
    if (isTomorrow(date)) return i18n.t('Tomorrow')
    return formatDate(date)
  }

const getFormatDistanceStrictShort = () => (start, end, locale: LocaleType) => {
  const interval = intervalToDuration({
    start,
    end
  })
  const intervalKeyToDisplay =
    ['years', 'months', 'days', 'hours', 'minutes', 'seconds'].find(
      intervalKey => interval[intervalKey] > 0
    ) || 'seconds'
  const total = interval[intervalKeyToDisplay]
  const parsedLocale = parseLocale(locale)
  const shortDistanceFormats = {
    en: {
      years: `${total} y`,
      months: `${total} m`,
      days: `${total} d`,
      hours: `${total} h`,
      minutes: `${total} min`,
      seconds: `${total} sec`
    },
    es: {
      years: `${total} a`,
      months: `${total} m`,
      days: `${total} d`,
      hours: `${total} h`,
      minutes: `${total} min`,
      seconds: `${total} seg`
    },
    fr: {
      years: `${total} a`,
      months: `${total} m`,
      days: `${total} j`,
      hours: `${total} h`,
      minutes: `${total} min`,
      seconds: `${total} sec`
    },
    pt: {
      years: `${total} a`,
      months: `${total} m`,
      days: `${total} d`,
      hours: `${total} h`,
      minutes: `${total} min`,
      seconds: `${total} seg`
    }
  }
  return shortDistanceFormats[parsedLocale][intervalKeyToDisplay]
}

/**
 * Helper that pad a number with zero on left by 2 digits
 *
 * @param {*} num
 */
export const padNumber = (num: number): string => {
  return `${num < 10 ? '0' : ''}${num}`
}

/**
 *
 * @param {*} time
 */
export const convertFromMilitaryTime = (time: any) => {
  const val = time.toString()

  let hours = 0
  if (val.length > 2) {
    hours = val.substring(0, val.length - 2)
  }
  const minutes = val.substring(val.length - 2, val.length)

  return `${padNumber(parseInt(hours))}:${padNumber(parseInt(minutes))}`
}

export const convertMinsToHrsMins = (minutes: number) => {
  const h = Math.floor(minutes / 60)
  const m = minutes % 60
  return m > 0 ? `${h}h ${m}m` : `${h}h`
}

let format = getFormatFunction()
export let formatDayName = getFormatDayNameFunction()
export let formatDistanceStrictShort = getFormatDistanceStrictShort()

i18n.on('languageChanged init', () => {
  format = getFormatFunction()
  formatDayName = getFormatDayNameFunction()
  formatDistanceStrictShort = getFormatDistanceStrictShort()
})
